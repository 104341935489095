import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import logo from "../images/FullLogo.svg";

//Component affichant la top bar
const TopBar = (props) => {
  const { toggleModal, title } = props;
  const isMobile = useMediaQuery({
    query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)`,
  });
  return (
    <div className="fullTopBar">
      <div className="topBar">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        {isMobile ? (
          <a href={cst.DEEPLINK} style={{ textDecoration: "none" }}>
            <div className="download">
              <span className="downloadPolice"> ouvrir l'app</span>
            </div>
          </a>
        ) : (
          <>
            {title && <p className="header-title">{title}</p>}
            <div
              className="topBar-store"
              onClick={toggleModal}
              style={{ cursor: "pointer" }}
            >
              <p className="topBar-store-text">DISPONIBLE SUR</p>
              <svg
                className="topBar-store-icon"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 0H3C1.344 0 0 1.344 0 3V25C0 26.656 1.344 28 3 28H25C26.656 28 28 26.656 28 25V3C28 1.344 26.656 0 25 0ZM7.938 22.031C7.77224 22.3177 7.49938 22.5268 7.17944 22.6123C6.8595 22.6978 6.5187 22.6528 6.232 22.487C5.9453 22.3212 5.73619 22.0484 5.65068 21.7284C5.56516 21.4085 5.61024 21.0677 5.776 20.781L6.67 19.237C7.676 18.931 8.501 19.168 9.145 19.949L7.938 22.031ZM16.619 18.663H5.25C4.562 18.663 4 18.1 4 17.413C4 16.726 4.563 16.163 5.25 16.163H8.438L12.525 9.088L11.244 6.876C11.0791 6.5891 11.0346 6.24859 11.12 5.92891C11.2054 5.60924 11.414 5.33639 11.7 5.17C11.9869 5.00513 12.3274 4.96055 12.6471 5.046C12.9668 5.13145 13.2396 5.33997 13.406 5.626L13.962 6.588L14.518 5.626C14.6001 5.48404 14.7093 5.35964 14.8395 5.25989C14.9696 5.16015 15.1181 5.08702 15.2766 5.04468C15.435 5.00233 15.6002 4.99161 15.7627 5.01311C15.9253 5.03461 16.082 5.08792 16.224 5.17C16.366 5.25208 16.4904 5.36131 16.5901 5.49146C16.6898 5.62162 16.763 5.77014 16.8053 5.92856C16.8477 6.08698 16.8584 6.25218 16.8369 6.41474C16.8154 6.57731 16.7621 6.73404 16.68 6.876L11.318 16.164H15.199C16.461 16.164 17.168 17.645 16.618 18.664L16.619 18.663ZM22.75 18.663H20.937L22.162 20.782C22.506 21.382 22.299 22.145 21.706 22.488C21.106 22.832 20.343 22.625 20 22.032C17.944 18.476 16.406 15.801 15.375 14.026C14.331 12.213 15.075 10.401 15.819 9.789C16.638 11.208 17.863 13.333 19.5 16.164H22.75C23.0815 16.164 23.3995 16.2957 23.6339 16.5301C23.8683 16.7645 24 17.0825 24 17.414C24 17.7455 23.8683 18.0635 23.6339 18.2979C23.3995 18.5323 23.0815 18.663 22.75 18.663Z"
                  fill="white"
                />
              </svg>
              <svg
                className="topBar-store-icon"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 3C0 1.34315 1.34315 0 3 0H25C26.6569 0 28 1.34315 28 3V25C28 26.6569 26.6569 28 25 28H3C1.34315 28 0 26.6569 0 25V3ZM4 4.21495V23.785C4.00012 23.8275 4.01212 23.869 4.03448 23.9043C4.05685 23.9396 4.08858 23.9671 4.12571 23.9834C4.16283 23.9997 4.2037 24.0041 4.24318 23.996C4.28266 23.988 4.319 23.9677 4.34764 23.9379L14 14.0005L4.34764 4.06208C4.319 4.03227 4.28266 4.01205 4.24318 4.00395C4.2037 3.99586 4.16283 4.00025 4.12571 4.01658C4.08858 4.0329 4.05685 4.06044 4.03448 4.09573C4.01212 4.13102 4.00012 4.1725 4 4.21495ZM6.29791 3.03216L18 10.1025L15.2357 13L6.06257 3.38078C5.89747 3.20672 6.08902 2.90762 6.29061 3.02766L6.29791 3.03216ZM6.0631 24.6193C5.89709 24.7933 6.08865 25.0924 6.29115 24.9723L6.29845 24.9678L18 17.8982L15.2356 15L6.0631 24.6193ZM20.0695 11L23.334 12.8369C24.222 13.3383 24.222 14.6631 23.334 15.1645L20.0695 17L17 14.0007L20.0695 11Z"
                  fill="white"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
