import PeopleHorizontalList from "./PeopleHorizontalList";
import { pluralize } from "../utils/functions";

//Component affichant un échantillon des personnes qui follow le livre
const PeopleFollowsWork = (props) => {
  const { bookOwners } = props;
  const owners = bookOwners.owners;
  const ownersTotal = FormatFollowCount(bookOwners.ownersTotal);
  return (
    <div>
      <div className="containerResume">
        <h2 className="resumeTitlePolice">
          {pluralize(
            bookOwners.ownersTotal,
            "1 personne l'a dans sa bibliothèque",
            `${ownersTotal} personnes l'ont dans leur bibliothèque`,
            "Personne ne l'a dans sa bibliothèque"
          )}
        </h2>
      </div>
      <div>
        <PeopleHorizontalList peopleFollow={owners} />
      </div>
    </div>
  );
};

//Function pour passer de 1000 à 1k
function FormatFollowCount(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export default PeopleFollowsWork;
