import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import iconProcurer from "../images/Acheter.svg";



//Component affichant le bouton se procurer sur la fiche livre
const SeProcurer = () => {
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  return (
    <div>
      <div className="flexProcurer">
        <div className="containerProcurer">
          <div className="circleProcurer">
            <img className="reducedIconProcurer" src={iconProcurer} alt="Se Procurer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeProcurer;
