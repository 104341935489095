import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import cst from "../utils/constant";
import close from "../images/Fermer.svg";
import logo from "../images/logo.svg";
import { DownloadApp } from "../utils/functions";

//Component qui affiche la bottom bar pour la fiche livre
const BottomBarBook = (props) => {
  const { showingOnChange } = props;
  const [show, setShow] = useState(true);
  const isMobile = useMediaQuery({
    query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)`,
  });
  return (
    <div
      className="bottomBarBook"
      style={
        show
          ? { position: "fixed", zIndex: 50 }
          : { position: "relative", background: "#252525" }
      }
    >
      {show && (
        <img
          onClick={() => setShow(false)}
          src={close}
          alt="close"
          className="closeBottomBar"
        />
      )}
      <div className="containerBottomBar">
        <div
          className="defaultProfile containerProfile"
          onClick={() => showingOnChange()}
          style={{ cursor: "pointer" }}
        >
          <img src={logo} alt="Profil picture" />
        </div>
        <div className="containerTextBottomBar">
          <span className="titleBottomBarPolice">Voir plus de contenu</span>
          <span className="textBottomBarPolice">
            {isMobile
              ? "Connectez-vous à Gleeph et faites le plein d’idées de lecture."
              : "Installez Gleeph sur votre smartphone et faites le plein d’idées de lecture."}
          </span>
        </div>
        <DownloadApp
          isMobile={isMobile}
          show={show}
          setShow={setShow}
          showingOnChange={showingOnChange}
        />
      </div>
    </div>
  );
};

export default BottomBarBook;
