import helpers from "../utils/APIHelper";
import { formatUrl } from "../utils/functions";
import { Helmet } from "react-helmet-async";
import model from "../models/model";
import React from "react";
import BackGroundBook from "../components/BackGroundBook";
import AuthorPanel from "../components/AuthorPanel";
import Popularity from "../components/Popularity";
import ResumePanel from "../components/ResumePanel";
import BottomBarBook from "../components/BottomBarBook";
import SeProcurer from "../components/SeProcurer";
import PeopleFollowsWork from "../components/PeopleFollowsWork";
import CommentOnWork from "../components/CommentOnWork";
import ShelvesRelation from "../components/ShelvesRelation";
import WorksInSeries from "../components/WorksInSeries";
import WorksSameAuthor from "../components/WorksSameAuthor";
import SimilarsBooks from "../components/SimilarsBooks";
import ProviderName, { logos } from "../components/ProviderName";
import Lottie from "react-lottie";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";
import TopBar from "../components/TopBar";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

//Composant regroupant tous les éléments de la page livre
export default class BookScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      book: model.book,
      loading: true,
      id: null,
      canonicalUrl: ""
    };
  }

  async componentDidMount() {
    const pathName = window.location.pathname.split("/");
    const id = pathName[3];
    this.setState({ id });
    const res = await helpers.fetchBook(id);
    if (res.message === "NotExist") {
      alert("Le livre n'existe pas");
    } else {
      this.setState({ book: res, loading: false });
    }
    const authors = res.data.authors;
    const title = res.data.title;
    const bestshotTitle = res.data.bestshotTitle;
    const newUrl = formatUrl(id, authors, title);
    const canonicalUrl = formatUrl(id, authors, bestshotTitle, true);
    window.history.pushState({}, "", newUrl);
    this.setState({ canonicalUrl });
  }

  shouldShowProvider() {
    const { provider } = this.state.book.data;
    return logos[provider] !== undefined;
  }

  render = () => {
    const {
      additionalData,
      data,
      bookOwners,
      similarBooks,
      inSeries,
      bySameAuthor,
      comments,
      shelvesWithThisBook
    } = this.state.book;
    const { loading, id, canonicalUrl } = this.state;
    const stats = additionalData.stats;
    data.description = !data.description ? "" : data.description;

    const { toggleModal } = this.props;

    const truncateDescription = (description) => {
      const maxLength = 155;
      if (description.length <= maxLength) {
        return description;
      }
      let truncated = description.slice(0, maxLength - 3);
      truncated = truncated.slice(0, truncated.lastIndexOf(" "));
      const lastCharacter = truncated.slice(-1);
      if (["?", "!", "."].includes(lastCharacter)) {
        return truncated;
      } else {
        return `${truncated}...`;
      }
    };

    const canonicalEAN =
      data.bestshot !== null && data.bestshot !== undefined
        ? data.bestshot
        : data.glid
        ? data.glid.replace(/[^0-9]/g, "")
        : id;

    const metaDescription =
      data.description.length > 0
        ? truncateDescription(data.description)
        : `Fiche livre de : ${data.title} contenant les avis des utilisateurs de Gleeph.`;
    return (
      <>
        <Helmet>
          <title>{data.title} - {data.authors[0]} | Gleeph</title>
          <meta name="description" content={metaDescription} />
          <link rel="manifest" href="/site.webmanifest"></link>
          <link
            rel="canonical"
            href={`https://gleeph.com/share/book/${canonicalEAN}/${canonicalUrl}`}
          />
        </Helmet>
        <TopBar toggleModal={toggleModal} />
        <div className="backgroundGrey">
          <div className="fixWidth">
            <div className="backgroundContent">
              <div onClick={toggleModal} style={{ cursor: "pointer" }}>
                <BackGroundBook book={data} loading={loading} canonicals={`${canonicalEAN}/${canonicalUrl}`} />
              </div>
              <div onClick={toggleModal} style={{ cursor: "pointer" }}>
                <SeProcurer />
              </div>
              <div
                className="backgroundOpacityListBookInShelf"
                style={{ height: 16 }}
              />
              {loading ? (
                <div>
                  <Lottie options={defaultOptions} width={50} />
                </div>
              ) : (
                <div>
                  <div onClick={toggleModal} style={{ cursor: "pointer" }}>
                    <AuthorPanel name={data.authors[0]} />
                  </div>
                  <div className="separateLine" />
                  <div onClick={toggleModal} style={{ cursor: "pointer" }}>
                    <Popularity tags={stats} />
                  </div>
                  {data.description.length > 0 && (
                    <div>
                      <div className="separateLine" />
                      <ResumePanel description={data.description} />
                    </div>
                  )}
                  {bookOwners.ownersTotal > 0 && (
                    <div>
                      <div className="separateLine" />
                      <div onClick={toggleModal} style={{ cursor: "pointer" }}>
                        <PeopleFollowsWork bookOwners={bookOwners} />
                      </div>
                    </div>
                  )}
                  {stats.comments > 0 && (
                    <div>
                      <div className="separateLine" />
                      <CommentOnWork
                        commentInfo={comments}
                        commentCount={stats.comments}
                      />
                    </div>
                  )}
                  {shelvesWithThisBook.length > 0 && (
                    <div>
                      <div className="separateLine" />
                      <ShelvesRelation shelves={shelvesWithThisBook} />
                    </div>
                  )}
                  {data.isSeries && inSeries.length > 0 && (
                    <div>
                      <div className="separateLine" />
                      <WorksInSeries
                        works={inSeries}
                        seriesDisplayName={data.seriesDisplayName}
                      />
                    </div>
                  )}
                  {bySameAuthor.length > 0 && (
                    <div>
                      <div className="separateLine" />
                      <WorksSameAuthor works={bySameAuthor} />
                    </div>
                  )}
                  {similarBooks.length > 0 && (
                    <div>
                      <div className="separateLine" />
                      <SimilarsBooks works={similarBooks} />
                    </div>
                  )}
                  {this.shouldShowProvider() && (
                    <div>
                      <div className="separateLine" />
                      <ProviderName provider={data.provider} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <BottomBarBook owner="default" showingOnChange={toggleModal} />
        </div>
      </>
    );
  };
}
