import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import iconShare from "../images/iconShare.svg";
import iconShareLoading from "../images/iconShareLoading.svg";
import iconLike from "../images/likeComment.svg";
import iconLikeMin from "../images/likeCommentMin.svg";
import iconComment from "../images/comComment.svg";
import iconCommentMin from "../images/comCommentMin.svg";
import DefaultPeople from "../components/DefaultPeople";
import { pluralize } from "../utils/functions";

const icon = {
  mini: {
    like: iconLikeMin,
    comment: iconCommentMin,
  },
  normal: {
    like: iconLike,
    comment: iconComment,
  },
};

//Component affichant les informations du propriétaire de l'étagère
const OwnerShelfPanel = (props) => {
  const { loading } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  if (loading) {
    return <LoadingOwnerShelfPanel screen={screen} />;
  }
  return <OwnerShelfPanelFull screen={screen} shelf={props.shelf} />;
};

//Component affichant les informations du propriétaire de l'étagère une fois la requête finit
const OwnerShelfPanelFull = (props) => {
  const { shelf, screen } = props;
  const userPicture = shelf.owner.picture;
  return (
    <div>
      <div className="backgroundShelfAuthor">
        <div className="ownerShelf">
          {userPicture ? (
            <img
              src={cst.PEOPLE_PICTURE + userPicture + cst.FIN_LIEN}
              alt="Profil picture"
              className="roundPictureProfil"
            />
          ) : (
            <DefaultPeople style="roundPictureProfil" />
          )}

          <div
            className="containerNameOwner"
            style={{ paddingLeft: 8, paddingRight: 10 }}
          >
            <span className="nameOwnerPolice" style={{ paddingRight: 7 }}>
              {shelf.owner.nickname}
            </span>
            <span className="nameOwnerPolice">•</span>
          </div>
          <img src={iconShare} alt="icon" style={{ paddingBottom: 5 }} />
          <span className="nbFollowersPolice" style={{ paddingLeft: 6 }}>
            {shelf.followCount}{" "}
            {pluralize(shelf.followCount, "abonné", "abonnés")}
          </span>
        </div>
        <div className="containerDescShelf">
          <span className="descShelfPolice">{shelf.description}</span>
          <hr size="1" color="#B2B2B2" />
          <div className="containerCommentInfo">
            <div className="likeComPolice">
              <span style={{ fontWeight: 600 }}>{shelf.likeCount}</span>{" "}
              {pluralize(shelf.likeCount, "aime", "aiment")}{" "}
              <span style={{ fontWeight: 600 }}>∙ {shelf.commCount}</span>{" "}
              {pluralize(shelf.commCount, "commentaire", "commentaires")}
            </div>
            <div>
              <img
                src={icon[screen]["like"]}
                alt="like"
                className="paddingLikeComment"
              />
              <img src={icon[screen]["comment"]} alt="comment" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//Component attendant le résultat de la requête
const LoadingOwnerShelfPanel = (props) => {
  const { screen } = props;
  return (
    <div>
      <div className="backgroundShelfAuthor">
        <div className="ownerShelf">
          <div
            className="roundPictureProfil"
            style={{ background: "#E3E3E3" }}
          />
          <div
            className="nbFollowersPolice nameOwnerPolice"
            style={{
              paddingLeft: 8,
              paddingRight: 10,
              color: "#E3E3E3",
            }}
          >
            <span
              className="ownerpanel-police-loading"
              style={{
                marginRight: 7,
              }}
            >
              gleeph
            </span>
            <span>•</span>
          </div>
          <img src={iconShareLoading} alt="icon" style={{ paddingBottom: 5 }} />
          <span
            className="nbFollowersPolice ownerpanel-police-loading"
            style={{ marginLeft: 6, color: "#E3E3E3" }}
          >
            abonnéeeees
          </span>
        </div>
        <div className="containerDescShelf">
          <div className="ownershelf-loading-up" />
          <div className="ownershelf-loading-down" />
          <hr size="1" color="#B2B2B2" />
          <div className="containerCommentInfo">
            <div style={{ color: "#E3E3E3" }}>
              <span className="ownerpanel-police-loading">comments</span>{" "}
              <span style={{ fontWeight: 600 }}>∙ </span>
              <span className="ownerpanel-police-loading">comments</span>
            </div>
            <div>
              <img
                src={icon[screen]["like"]}
                alt="like"
                className="paddingLikeComment"
              />
              <img src={icon[screen]["comment"]} alt="comment" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerShelfPanel;
