import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import useDrag from "../utils/useDrag";
import DefaultPeople from "./DefaultPeople";
import { onWheel } from "../utils/functions";
import cst from "../utils/constant";

//Composant qui render la list de followPeople
function PeopleHorizontalList(props) {
  let items = props.peopleFollow;

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragStop} style={{ cursor: "pointer" }}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        <div className="bookscreen-peoplefollow-allcard-container" />
        {items.map((ppl) => (
          <Card
            itemId={ppl.id} // NOTE: itemId is required for track items
            nickname={ppl.nickname}
            picture={ppl.picture}
            key={ppl.id}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

//Composant de chaque people
function Card(props) {
  const { itemId, nickname, picture } = props;
  return (
    <div tabIndex={0}>
      <div className="bookscreen-peoplefollow-listpeople-container noselect">
        <span key={itemId}>
          {picture ? (
            <img
              key={itemId}
              src={cst.PEOPLE_PICTURE + picture + cst.FIN_LIEN}
              alt="People Picture"
              className="bookscreen-peoplefollow-picture-style"
            />
          ) : (
            <DefaultPeople style="bookscreen-peoplefollow-picture-style" />
          )}
        </span>
        <span className="bookscreen-peoplefollow-nickname-police">
          {nickname ? nickname : "Gleepher"}
        </span>
      </div>
    </div>
  );
}

export default PeopleHorizontalList;
