// eslint-disable-next-line
export default {
  shelf: {
    id: "WORK_CUSTOM-250EC938BD5D43D4A3F84918ADA15FD9-0F7A0013BE46421A8E764BF49D88EB6B",
    owner: {
      id: "250EC938BD5D43D4A3F84918ADA15FD9",
      picture:
        "4e22dfb206c9bc569211d17c766aa5352a370cfb16f28acbae94d34db82359e4",
      firstname: "",
      nickname: "Zeratul",
      lastname: "",
      isFederated: false,
      canTalk: false,
      friendStatus: "me",
      isFollower: false,
      isFollowed: false,
      more: {
        follower: 8,
        followee: 13,
      },
    },
    collabCount: 0,
    image: "",
    followCount: 0,
    color: "#FC86B7",
    booksCover: [
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
      {
        id: "9782075138581",
        title: "Trotro et Zaza à la boulangerie",
        authors: ["Bénédicte Guettier"],
        image:
          "2f0b89731112820619d74f23916b410d42306f4a6521fec19a810ddc696bf27f",
      },
      {
        id: "9782075120173",
        title: "Trotro et Zaza se disputent",
        authors: ["Bénédicte Guettier"],
        image:
          "ca5c4acb6483c2f512eb784c36f0501b95ec851577de39678703b04a0ee1f30c",
      },
      {
        id: "9782075120159",
        title: "Trotro et Zaza à la mer",
        authors: ["Bénédicte Guettier"],
        image:
          "16d331e5973aea25fbebad1256d93867e39e38bffc45626d72f240dfe9c1d9e1",
      },
    ],
    collaborative: false,
    icon: "",
    description: "Trotro rigolo",
    privacy: "PUBLIC",
    updatedOn: 1632495269776,
    internalType: "CUSTOM",
    type: "CLASSIC",
    createdOn: 1632495015761,
    booksCount: 113,
    deleted: false,
    booksLimit: -1,
    name: "Trotro",
    isFollowed: false,
    isPinned: true,
    isLiked: false,
    hasCommented: false,
    commCount: 0,
    likeCount: 0,
  },

  book: {
    additionalData: {
      stats: {
        comments: 136,
        follow: 31903,
        like: 13490,
        own: 21470,
        read: 25562,
        reading: 1015,
        wish: 907,
      },
    },
    data: {
      authors: ["J. K. Rowling"],
      clilObj: {
        color: "#F2D60F",
        id: "3744",
        libelle: "Fiction Jeunesse",
        topId: "3722",
      },
      description:
        "Harry Potter est un garçon ordinaire. Mais le jour de ses onze ans, son existence bascule : un géant vient le chercher pour l'emmener dans une école de sorciers. Quel mystère entoure donc sa naissance et qui est l'effroyable V..., le mage dont personne n'ose prononcer le nom ? Voler à cheval sur des balais, jeter des sorts, combattre les Trolls : Harry Potter se révèle un sorcier vraiment doué.  Mais un mystère entoure sa naissance et l'ffroyable V., le mage dont personne n'ose prononcer le nom. Quand il décide, avec ses amis, d'explorer les moindres recoins de son école, il va se trouver entraîné dans d'extraordinaires aventures.",
      edition: "Gallimard-jeunesse",
      glid: "GL-9782070518425",
      id: "9782070584628",
      image: "413b227051c993b9b9ee387e05cbc4743cdcc690716e1114644b77d334479a17",
      labels: [],
      lang: ["français"],
      more: {
        communities: [Array],
      },
      order: { hasOrder: false },
      pages: "320",
      price: 8.9,
      publicationYear: "2017",
      publisher: "Gallimard-jeunesse",
      publishing_date: 1507759200000,
      tags: {
        comment: false,
        creation: 1623061160691,
        follow: true,
        like: true,
        own: true,
        read: false,
        reading: true,
        wish: true,
      },
      title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      updatedOn: 1594206327699,
    },
    bookOwners: {
      owners: [
        {
          id: "",
          nickname: "anonyme1",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme2",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme3",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme4",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme5",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme6",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme7",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme8",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme9",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme10",
          picture: "",
        },
      ],
      ownersTotal: 10,
    },
    comments: [
      {
        data: {
          tags: {
            wish: true,
            like: true,
            own: true,
            reading: true,
            read: true,
          },
          updatedOn: 1637061894000,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture: "",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
      {
        data: {
          tags: {
            wish: false,
            like: true,
            own: true,
            reading: false,
            read: true,
          },
          updatedOn: 1635159647424,
          content: "Même à 28 ans cette saga reste un plaisir à lire 💙",
          title: "Toujours un moment de plaisir ",
          likeCount: 3,
          commCount: 0,
          picture:
            "9ede26bf572c90e457681d0c0be8223df1775e768b4db09669501fe39384bb87",
          author: "Ary Oromë",
          id: "9782070584642-88EA1AEEF41146689B407577C5DA5AFD",
        },
      },
    ],
    similarBooks: [
      {
        provider: "Electre",
        title: "Jack & la grande aventure du cochon de Noël",
        authors: ["J.K. Rowling"],
        id: "9782075163057",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image: "",
        relevance: 0,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 2, Harry Potter et la chambre des secrets",
        authors: ["J.K. Rowling"],
        id: "9782075158886",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "a3282cac66026c17888ec59cfcff0de822b5ea5f2b2a7bcf003e41ce9b56c98e",
        relevance: 1,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782075145930",
        publicationYear: "2020",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "7a9cbc3f101326b09eb04ad6e11a4c6616d70f4d0faf3aae7be1a588fd56eea1",
        relevance: 2,
        more: {},
      },
      {
        provider: "Electre",
        title: "Jack et la grande aventure du cochon de Noël",
        authors: ["J.K. Rowling"],
        id: "9782075163330",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "f3ac6963de9d9f44b3a94c6f759b0b8d7da2019b055cba624f54c7d93ded4950",
        relevance: 3,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782070584628",
        publicationYear: "2017",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "3079dec9539cd840b148fd451c58a9910ca4b71693ac0ba391e568b36477c443",
        relevance: 4,
        more: {},
      },
      {
        provider: "Electre",
        title: "L'Ickabog",
        authors: ["J.K. Rowling"],
        id: "9782075150552",
        publicationYear: "2020",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "37eeaa684d324de16888ba37ff65863d179473df8d8ccd28905ff54c60635968",
        relevance: 5,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782070669073",
        publicationYear: "2015",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "146d784b64b82a76eb72367161909efbac6e35eafd8b8eaa01af391a24c51ab5",
        relevance: 6,
        more: {},
      },
      {
        provider: "Electre",
        title: "L'Ickabog",
        authors: ["J.K. Rowling"],
        id: "9782075156639",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "6eef128f2451abe8c922ae6974745011c1a5bf3d6c19eed9b11c9066f4bddbad",
        relevance: 7,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 4, Harry Potter et la coupe de feu",
        authors: ["J.K. Rowling"],
        id: "9782075090384",
        publicationYear: "2019",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "5d2fa275d86ff886c74d98faed95bd451a4d35be3120a77d10f3030854f45963",
        relevance: 8,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 2, Harry Potter et la chambre des secrets",
        authors: ["J.K. Rowling"],
        id: "9782070584642",
        publicationYear: "2017",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "054a51a88688d71baf23941f63365e633868aae4e78f82ebbb17a3f5decc1895",
        relevance: 9,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782075105026",
        publicationYear: "2018",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "a9bd3ee9cd2c4af7731f297fa072036b5029a103b11273bf98ae10458ef596ce",
        relevance: 10,
        more: {},
      },
    ],
    bySameAuthor: [
      {
        provider: "Electre",
        title: "Jack & la grande aventure du cochon de Noël",
        authors: ["J.K. Rowling"],
        id: "9782075163057",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "8a52502067094008b293b3ff2a15d45c3bcd293b37aad42a9e0b73c8a7db1c94",
        relevance: 0,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 2, Harry Potter et la chambre des secrets",
        authors: ["J.K. Rowling"],
        id: "9782075158886",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "a3282cac66026c17888ec59cfcff0de822b5ea5f2b2a7bcf003e41ce9b56c98e",
        relevance: 1,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782075145930",
        publicationYear: "2020",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "7a9cbc3f101326b09eb04ad6e11a4c6616d70f4d0faf3aae7be1a588fd56eea1",
        relevance: 2,
        more: {},
      },
      {
        provider: "Electre",
        title: "Jack et la grande aventure du cochon de Noël",
        authors: ["J.K. Rowling"],
        id: "9782075163330",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "f3ac6963de9d9f44b3a94c6f759b0b8d7da2019b055cba624f54c7d93ded4950",
        relevance: 3,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782070584628",
        publicationYear: "2017",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "3079dec9539cd840b148fd451c58a9910ca4b71693ac0ba391e568b36477c443",
        relevance: 4,
        more: {},
      },
      {
        provider: "Electre",
        title: "L'Ickabog",
        authors: ["J.K. Rowling"],
        id: "9782075150552",
        publicationYear: "2020",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "37eeaa684d324de16888ba37ff65863d179473df8d8ccd28905ff54c60635968",
        relevance: 5,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782070669073",
        publicationYear: "2015",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "146d784b64b82a76eb72367161909efbac6e35eafd8b8eaa01af391a24c51ab5",
        relevance: 6,
        more: {},
      },
      {
        provider: "Electre",
        title: "L'Ickabog",
        authors: ["J.K. Rowling"],
        id: "9782075156639",
        publicationYear: "2021",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "6eef128f2451abe8c922ae6974745011c1a5bf3d6c19eed9b11c9066f4bddbad",
        relevance: 7,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 4, Harry Potter et la coupe de feu",
        authors: ["J.K. Rowling"],
        id: "9782075090384",
        publicationYear: "2019",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "5d2fa275d86ff886c74d98faed95bd451a4d35be3120a77d10f3030854f45963",
        relevance: 8,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 2, Harry Potter et la chambre des secrets",
        authors: ["J.K. Rowling"],
        id: "9782070584642",
        publicationYear: "2017",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "054a51a88688d71baf23941f63365e633868aae4e78f82ebbb17a3f5decc1895",
        relevance: 9,
        more: {},
      },
      {
        provider: "Electre",
        title: "Harry Potter. Volume 1, Harry Potter à l'école des sorciers",
        authors: ["J.K. Rowling"],
        id: "9782075105026",
        publicationYear: "2018",
        clil: "3749",
        clilObj: {
          id: "3749",
          libelle: "Romans",
          color: "#F2D60F",
          topId: "3722",
        },
        image:
          "a9bd3ee9cd2c4af7731f297fa072036b5029a103b11273bf98ae10458ef596ce",
        relevance: 10,
        more: {},
      },
    ],
    shelvesWithThisBook: [
      {
        id: "WORK_CUSTOM-DAD08E3AD7D44210B437D570E08FFC18-13DD733A1BE444AA95B8085E897D4127",
        owner: {
          id: "DAD08E3AD7D44210B437D570E08FFC18",
          firstname: "Victoire",
          picture: "",
          nickname: "Victiore",
          lastname: "",
          isFederated: true,
          canTalk: true,
          friendStatus: "none",
          isFollower: false,
          isFollowed: false,
          more: {
            follower: 7,
            followee: 6,
          },
        },
        collabCount: 0,
        image:
          "ddb74fc4dd096902e6c0018642cafb00e2430564d85b24e4de3c46954b951daf",
        followCount: 2,
        color: "#92A4FF",
        booksCover: [
          {
            id: "9782702436196",
            title: "Comment sauver un vampire amoureux",
            authors: ["Beth Fantaskey"],
            image:
              "2df903bbc9f03d1da9e755a1b0eee0e4852e7a85477812ec1cca296a7f1fb3c8",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782221144572",
            title: "Après nous t.1",
            authors: ["Myra ELJUNDIR"],
            image:
              "fe42d3a4c25a1edbc860563c7552bb8bdee66fc229c74d43fbac3d072fdc9d51",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782897860363",
            title: "La couronne du vampire t.1 ; les Orderles",
            authors: ["Martin Daneau"],
            image:
              "5c3b7685e4c3a4766ba8332f682a8ba304dcc91058f7fa3c8926fc25e8dce059",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9791032404324",
            title: "Peine-ombre",
            authors: ["Ariel Holzl"],
            image:
              "a1338b7d973f2199f96d372c715e97cb194ac730bf99e95c3693f118a6b7c7be",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782266209939",
            title: "Shadow hills",
            authors: ["Anastasia Hopcus"],
            image:
              "db8f3b9ea345336f59890ca25ba8daf79fad64f0987eb94335abe793292755ab",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782381990255",
            title: "Les autres, integrale",
            authors: ["Sandra Moyon"],
            image: "",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782381990071",
            title: "Les Gardiens. Volume 1",
            authors: ["Sandra Moyon"],
            image:
              "a229015594c7ff775e97fa8b999b5f91c3d4c86d8f577ccaf0d4e5aa3da12c5f",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782378761233",
            title: "Serpent & dove (broche)",
            authors: ["Mahurin Shelby"],
            image: "",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782745965820",
            title: "Half bad t.3 ; quête noire",
            authors: ["Sally Green"],
            image:
              "c17f86e784218e467865bbb5f8ea486d450fc6df9dd4d0205da0c873b8072327",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782745974167",
            title: "Half bad t.2 ; nuit rouge",
            authors: ["Sally Green"],
            image:
              "62b17a19a275bb334b60f0dfb33a29b0cf47eee8f87a1c8be37e1e7620bcb17a",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9791038117655",
            title:
              "Le cycle du corbeau - t01 - la prophetie de glendower - le cycle du corbeau, t1",
            authors: ["Maggie Stiefvater"],
            image:
              "746ba8967fff937d5e369eef6af488d75ab62737eea1aba9381d5bd14e344cc2",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9791094786482",
            title: "Blood witch, integrale",
            authors: ["Lena Jomahe"],
            image: "",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782702434437",
            title: "Comment se débarrasser d'un vampire amoureux ?",
            authors: ["Fantaskey-B"],
            image:
              "c82113d97011daccba65adf24b3590926fdef0a54f785fbbdbd57438214c8675",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782824601939",
            title: "Les morsures de la nuit",
            authors: ["Alexandra Harvey"],
            image:
              "37975f434daf5ae074f2b1b464dfcd96b2b8d0ccf47c97e29dcb848702abeb84",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782360510337",
            title: "Le livre perdu des sortilèges",
            authors: ["Deborah Harkness"],
            image:
              "7ceecc3771a3fb6bf8cb384c2b1fc2db472986a9ff756ad49fa04c4875c17839",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624553",
            title: "Harry Potter T.4 ; Harry Potter et la coupe de feu",
            authors: ["J. K. Rowling"],
            image:
              "427948dc15c55c41baf894c6428a563e8f8f235dec7a08fc1f442b61f0c55724",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624560",
            title: "Harry Potter T.5 ; Harry Potter et l'ordre du phénix",
            authors: ["J. K. Rowling"],
            image:
              "ed85e918875e97ca265b6ac1f28d852d1646a96945ae3a4da476b36e9eeb7ec4",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624522",
            title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
            authors: ["J. K. Rowling"],
            image:
              "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624911",
            title: "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
            authors: ["J. K. Rowling"],
            image:
              "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624904",
            title: "Harry Potter T.6 ; Harry Potter et le prince de sang-mêlé",
            authors: ["J. K. Rowling"],
            image:
              "edc9a33ef83e7e4ccc593641f62f47daa97ec9033989f0c8b80cea4939e2ba89",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
        ],
        collaborative: false,
        icon: "",
        description: "",
        privacy: "PUBLIC",
        updatedOn: 1636880121162,
        internalType: "CUSTOM",
        type: "CLASSIC",
        createdOn: 1614802043329,
        deleted: false,
        booksLimit: -1,
        name: "Fantastique / fantasy / Urban Fantasy",
        booksCount: 57,
        isFollowed: false,
        isPinned: false,
        isLiked: false,
        hasCommented: false,
        commCount: 0,
        likeCount: 1,
      },
      {
        id: "WORK_CUSTOM-4625CE0900E34101A931EA8835CA7686-F0D0B5E31D5541F58F327A20CA03C1BD",
        owner: {
          id: "4625CE0900E34101A931EA8835CA7686",
          picture:
            "5c1fee2cbefc323d7456331717627615fc5d3fbc03d178ecf728f900d6fd2779",
          firstname: "Audrey",
          nickname: "myhappinesslibrary",
          lastname: "",
          isFederated: false,
          canTalk: true,
          friendStatus: "none",
          isFollower: false,
          isFollowed: false,
          more: {
            follower: 18,
            followee: 17,
          },
        },
        collabCount: 0,
        image:
          "9feb7f091a5509e69dcb1c304969d2dfa695d6b37f1befd89def7b1ec2183cc6",
        followCount: 0,
        color: "#FC7E87",
        booksCover: [
          {
            id: "9782226319494",
            title: "Un(e)secte",
            authors: ["Maxime Chattam"],
            image:
              "3672f07324bfb36e5bb98b05f5f05daad7829965a5d7f86888a5399a156db0c3",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624522",
            title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
            authors: ["J. K. Rowling"],
            image:
              "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782070624539",
            title: "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
            authors: ["J. K. Rowling"],
            image:
              "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782737378706",
            title: "Les nouveaux secrets de Saint-Malo et de ses envir",
            authors: ["CHEREIL DE LA RIVIERE Oli"],
            image: "",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782847208696",
            title: "Les voyages de Cosme K",
            authors: ["Philippe Gerin"],
            image:
              "ae78186204836fe9cf1c283a986fffdfd1daa97ad5b2794e147f96ce8da6a0d2",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782226188632",
            title: "Autre-Monde T.1 ; l'alliance des trois",
            authors: ["Maxime Chattam"],
            image:
              "6d2a0ed7c63d274538cc193f9636fbf32b91b05df6c255c1c3dbd55f777a9ce6",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782501149679",
            title:
              "Une histoire de genres ; guide pour comprendre et défendre les transidentités",
            authors: ["Lexie"],
            image:
              "075ed54b67cd815008ed80b8e726b24b348a5124fb9a16d669956c4fc72370f0",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9791097222062",
            title: "La bête du bois perdu",
            authors: ["Nina Gorlier"],
            image:
              "a70cc2c17ec8ef77f7cef860d7f2ad176cfbd34fdc5b56744c09dc2846f4cb80",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782081264328",
            title: "Le cirque des rêves",
            authors: ["Erin Morgenstern"],
            image:
              "3d8a1dfed7b1eef1e73d4eb8109431780988c10b70de8f6e1e32c8706c1876f6",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782737373732",
            title: "Guide secret de Saint-Malo",
            authors: ["Olivier De La Riviere"],
            image:
              "8a983cab17633a702c4c9e7eac81aef510752a8766363609d3fc7ecfc35db541",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782253934189",
            title: "Quand nos souvenirs viendront danser",
            authors: ["Virginie Grimaldi"],
            image:
              "d66284a42dc0fd11bcd2dbf3eafdecafa96064707b6006a6a74f6b7f012d356a",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782092576731",
            title: "The hate U give ; la haine qu'on donne",
            authors: ["Angie Thomas"],
            image:
              "64ffbee4d63adbe1d1aa168888c0a77370ab1bf3f32a9eef888283a884f72c61",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782253088110",
            title: "Le parfum du bonheur est plus fort sous la pluie",
            authors: ["Virginie Grimaldi"],
            image:
              "5b693578689ad156236d5a1b657b236883155ea4f5d1950a1f4da025fa4d2296",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9782081411487",
            title: "Les crayons de couleur",
            authors: ["Jean-Gabriel Causse"],
            image:
              "84ab2562a6938b0ecfd75f8520f4fee9c8bfb81978bebd349d6bac9a43f1d6b3",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
          {
            id: "9791026901549",
            title: "L'ensoleillée",
            authors: ["Dany Rousson"],
            image:
              "c17788ebac13e98f8c6eca3cd989f1e617bc581fc467fc0062e2ae8318df9349",
            clilObj: {
              id: "999999",
              libelle: "SANS CATÉGORIE",
              color: "#979797",
              topId: null,
            },
            more: {},
          },
        ],
        collaborative: false,
        icon: "",
        description: "",
        privacy: "PUBLIC",
        updatedOn: 1636819601151,
        internalType: "CUSTOM",
        type: "CLASSIC",
        createdOn: 1612207821303,
        deleted: false,
        booksLimit: -1,
        name: "2021 Readings",
        booksCount: 33,
        isFollowed: false,
        isPinned: false,
        isLiked: false,
        hasCommented: false,
        commCount: 0,
        likeCount: 1,
      },
      // {
      //       id: "WORK_CUSTOM-287FBE54F0E34926AF33C8DBA091DFDC-505F714999C64F8D9ED8225B1EEEAE65",
      //       owner: {
      //         id: "287FBE54F0E34926AF33C8DBA091DFDC",
      //         picture:
      //           "bfde865653f531495070892a21694c8a22a9cc256b5971423d0d2e49194046e8",
      //         firstname: "",
      //         nickname: "CoralineW",
      //         lastname: "",
      //         isFederated: true,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 130,
      //           followee: 87,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 5,
      //       color: "#85DBDE",
      //       booksCover: [
      //         {
      //           id: "9791028121891",
      //           title: "Seule dans l'espace",
      //           authors: ["S. K. Vaughn"],
      //           image:
      //             "230aaba47bb1976fe481c25810b1177548d950a3d1a8f9dc7d746fb004773126",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221144572",
      //           title: "Après nous t.1",
      //           authors: ["Myra ELJUNDIR"],
      //           image:
      //             "fe42d3a4c25a1edbc860563c7552bb8bdee66fc229c74d43fbac3d072fdc9d51",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791035934514",
      //           title: "Native t.1 : le berceau des élus",
      //           authors: ["Laurence Chevallier"],
      //           image:
      //             "4fa8fe2153b34e1075722b350a195f74b42bf927695b8cbc8e7c7edbc35bc719",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378761370",
      //           title: "Le chant des sans repos. Vol. 1",
      //           authors: ["Roseanne A. Brown"],
      //           image:
      //             "0d1b3e4ad8f90fafcb816e077187532a51a8b4b879fe1984ac3588394183119c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378760540",
      //           title: "La duperie de guenievre (broche)",
      //           authors: ["White Kiersten"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378760731",
      //           title: "Le trone des sept iles (broche)",
      //           authors: ["Grace Adalyn"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378761295",
      //           title: "Lore (broche)",
      //           authors: ["Alexandra Bracken"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378761233",
      //           title: "Serpent & dove (broche)",
      //           authors: ["Mahurin Shelby"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791094786482",
      //           title: "Blood witch, integrale",
      //           authors: ["Lena Jomahe"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791038117655",
      //           title:
      //             "Le cycle du corbeau - t01 - la prophetie de glendower - le cycle du corbeau, t1",
      //           authors: ["Maggie Stiefvater"],
      //           image:
      //             "746ba8967fff937d5e369eef6af488d75ab62737eea1aba9381d5bd14e344cc2",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253261995",
      //           title: "Oraisons",
      //           authors: ["Samantha Bailly"],
      //           image:
      //             "071c149fc3836ab0bdc1c1c2199df4de5e4d5d1ee2f5456a251856f293e44260",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624560",
      //           title: "Harry Potter T.5 ; Harry Potter et l'ordre du phénix",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ed85e918875e97ca265b6ac1f28d852d1646a96945ae3a4da476b36e9eeb7ec4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624904",
      //           title:
      //             "Harry Potter T.6 ; Harry Potter et le prince de sang-mêlé",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "edc9a33ef83e7e4ccc593641f62f47daa97ec9033989f0c8b80cea4939e2ba89",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624553",
      //           title: "Harry Potter T.4 ; Harry Potter et la coupe de feu",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "427948dc15c55c41baf894c6428a563e8f8f235dec7a08fc1f442b61f0c55724",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624911",
      //           title:
      //             "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624546",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "7211a7590185c6ec13befd7b294bf98de708b69e80a8bead346b376aa76269da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782017170402",
      //           title: "Witch and god - ella la promise",
      //           authors: ["Stone Liv"],
      //           image:
      //             "8211436d6ec1806087e49b3dcd1d61b9abfc18467b80056ed6283ba3c388fc51",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782732499048",
      //           title: "The kiss of deception. the remnant chronicles, tome 1",
      //           authors: ["Mary Pearson"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "Livres en version numérique ",
      //       privacy: "PUBLIC",
      //       updatedOn: 1636649250851,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1603381051509,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Livres Kindle ❤️",
      //       booksCount: 434,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 1,
      //   },
      //   {
      //       id: "WORK_CUSTOM-287FBE54F0E34926AF33C8DBA091DFDC-5EB8B2AE41F6476DB18502BE399E8989",
      //       owner: {
      //         id: "287FBE54F0E34926AF33C8DBA091DFDC",
      //         picture:
      //           "bfde865653f531495070892a21694c8a22a9cc256b5971423d0d2e49194046e8",
      //         firstname: "",
      //         nickname: "CoralineW",
      //         lastname: "",
      //         isFederated: true,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 130,
      //           followee: 87,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 1,
      //       color: "#7ADAA9",
      //       booksCover: [
      //         {
      //           id: "9782070624546",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "7211a7590185c6ec13befd7b294bf98de708b69e80a8bead346b376aa76269da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811217792",
      //           title: "Le septième guerrier-mage",
      //           authors: ["Paul Beorn"],
      //           image:
      //             "4b9a178c6dcfc82b38ca48581b2a9cc3860198fa3971ef5d08c62927aa2d8f93",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782017170402",
      //           title: "Witch and god - ella la promise",
      //           authors: ["Stone Liv"],
      //           image:
      //             "8211436d6ec1806087e49b3dcd1d61b9abfc18467b80056ed6283ba3c388fc51",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791028120191",
      //           title: "Le long hiver t.1 : le règne glaciaire",
      //           authors: ["A. G. Riddle"],
      //           image:
      //             "9cc5a555d6199bc650a01fc7f859d59a31ec2a311059c94efa372a48c67c2b7e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791028112158",
      //           title: "L'anomalie",
      //           authors: ["Michael Rutger"],
      //           image:
      //             "e2e2316a25f6979c07135439753ecdcb3b0abbf8e89704e19d1cacb6685745ed",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782732493350",
      //           title: "The dance of thieves t.2 ; vow of thieves",
      //           authors: ["Mary Pearson"],
      //           image:
      //             "b6d2abfcc782f7ce184ac29adde901d0f4c9be4ebbea2709ed35672982c6a101",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782732493312",
      //           title: "Dance of thieves",
      //           authors: ["Mary E. Pearson"],
      //           image:
      //             "9859e7279b8dd74923010e7694963792685d8dab57df7d8bbeec5c8aed0a3658",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782700264173",
      //           title: "Un sort si noir et éternel",
      //           authors: ["Brigid Kemmerer"],
      //           image:
      //             "8c3f1ec76bc33fa70715951f46e67d7521a449512481dea270ffeb18358449da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290159668",
      //           title: "Si demain n'existe pas",
      //           authors: ["Jennifer L. Armentrout"],
      //           image:
      //             "9e6a02fa7b12db152782a7dd013ebbf9f4c443dc05cac0708b62b90c6d40c5b5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253237624",
      //           title: "La jeune fille et la nuit",
      //           authors: ["Guillaume Musso"],
      //           image:
      //             "3283bc2a8944b3655e0126c7ffbe691b0e8278a4aed31f741d66cd976e34afd2",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811611699",
      //           title: "L'attaque des titans T.1",
      //           authors: ["Hajime Isayama"],
      //           image:
      //             "6fa089320704e329937b9b1a8fdeffa5e2b8c2107461c5fb62cf81622eeb569b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811611705",
      //           title: "L'attaque des titans T.2",
      //           authors: ["Hajime Isayama"],
      //           image:
      //             "ac59338407aa49321f0cb825a460534f905d4d404616375d0add30fa508c2531",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266285490",
      //           title:
      //             "Tout ce qui est sur Terre doit périr ; la dernière licorne",
      //           authors: ["Michel Bussi"],
      //           image:
      //             "467364ba6f5476e7eaa0f73ef17840d0102ff414e6931eea54b19c899e71badd",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221250570",
      //           title: "Vampyria - livre 1 la cour des tenebres",
      //           authors: ["Victor Dixen"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "Toutes mes lectures de l'année ❤️ ",
      //       privacy: "PUBLIC",
      //       updatedOn: 1636646053634,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1628842836738,
      //       booksCount: 73,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "2021 📆",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 1,
      //   },
      //   {
      //       id: "WORK_CUSTOM-63653D70ADEC4DE5B0B41ACF25AABAD1-C82865DAC558463EB9C2192EC28035BB",
      //       owner: {
      //         id: "63653D70ADEC4DE5B0B41ACF25AABAD1",
      //         firstname: "",
      //         nickname: "Raphy",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 0,
      //           followee: 0,
      //         },
      //       },
      //       collabCount: 0,
      //       image:
      //         "b011e6eb50a0d9c68a7d03ca255528da7932e2b7656858fe531c79967790d16e",
      //       followCount: 0,
      //       color: "#92E4D1",
      //       booksCover: [
      //         {
      //           id: "9782732499048",
      //           title: "The kiss of deception. the remnant chronicles, tome 1",
      //           authors: ["Mary Pearson"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782203207509",
      //           title: "La malediction de highmoor",
      //           authors: ["Craig Erin A."],
      //           image:
      //             "c7530df9b6591f4d21760f59b93d5d8457617a850bd15b42b3729af967b2a7e0",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266244428",
      //           title:
      //             "The mortal instruments ; la cité des ténèbres T.4 ; la cité des anges déchus",
      //           authors: ["Cassandra Clare"],
      //           image:
      //             "66a0078ccc252109951aadd10310639d50ae69179193661af431d5645357c268",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266244312",
      //           title: "The mortal instruments. Volume 3, La cité de verre",
      //           authors: ["Cassandra Clare"],
      //           image:
      //             "c0a47b5f578b52eb490bd40fd7ea73d7dc6317306f83236cfb0d7fc3c9e989df",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266244305",
      //           title:
      //             "The mortal instruments ; la cité des ténèbres T.2 ; la cité des cendres",
      //           authors: ["Cassandra Clare"],
      //           image:
      //             "2c3b23a99b8f4b117e157cc0ee220d5a013bb0a4cf35d4af0e0ab71f30ca307e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782700276442",
      //           title: "Un sort si noir et eternel",
      //           authors: ["Kemmerer Brigid"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266244299",
      //           title:
      //             "The mortal instruments ; la cité des ténèbres T.1 ; la cité des ténèbres",
      //           authors: ["Cassandra Clare"],
      //           image:
      //             "5cef6aedc07c705746bcb995a02267e51b612da9eaa8157628a6df4035eac527",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782298163865",
      //           title: "Grisha",
      //           image:
      //             "5a4d166393b24d1f975c45e61a463f0b1f5855140c87ee31c06a425d44f18ba8",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290179369",
      //           title: "Les voleurs de fumee t2 le monde des demons",
      //           authors: ["Sally Green"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290179314",
      //           title: "Les voleurs de fumee",
      //           authors: ["Sally Green"],
      //           image:
      //             "a2410aab1a8f4874f12b94c19fb4e659ab5d9e9015c1f23818be584f00a49237",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221250686",
      //           title: "Vampyria - tome 2 la cour des miracles - vol02",
      //           authors: ["Victor Dixen"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221250570",
      //           title: "Vampyria - livre 1 la cour des tenebres",
      //           authors: ["Victor Dixen"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624560",
      //           title: "Harry Potter T.5 ; Harry Potter et l'ordre du phénix",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ed85e918875e97ca265b6ac1f28d852d1646a96945ae3a4da476b36e9eeb7ec4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782378761288",
      //           title: "Lore (relie)",
      //           authors: ["Alexandra Bracken"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782755687934",
      //           title: "Le collectionneur de voeux",
      //           authors: ["Mia Sheridan"],
      //           image:
      //             "614d99c3258295954b8c0db950585c43dfedcc49d700b897e9a1789e0da17f54",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782075074209",
      //           title: "Harry Potter et l'enfant maudit ; parties I et II",
      //           authors: ["J. K. Rowling", "John Tiffany", "Jack Thorne"],
      //           image:
      //             "a760de2e58fef4312ebb0d56a798eaf3fa4e09fa02f6b54f50d8d9610983cfc5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624911",
      //           title:
      //             "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624904",
      //           title:
      //             "Harry Potter T.6 ; Harry Potter et le prince de sang-mêlé",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "edc9a33ef83e7e4ccc593641f62f47daa97ec9033989f0c8b80cea4939e2ba89",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624546",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "7211a7590185c6ec13befd7b294bf98de708b69e80a8bead346b376aa76269da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624553",
      //           title: "Harry Potter T.4 ; Harry Potter et la coupe de feu",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "427948dc15c55c41baf894c6428a563e8f8f235dec7a08fc1f442b61f0c55724",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "Moment d’évasion…",
      //       privacy: "PUBLIC",
      //       updatedOn: 1636312690301,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1634509097713,
      //       booksCount: 32,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Fantaisie / Fantastique",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-4082E27BA60B48A7BB1000E60A7B19CF-228555EFB0684207A0E8F51E740691A3",
      //       owner: {
      //         id: "4082E27BA60B48A7BB1000E60A7B19CF",
      //         firstname: "Ma",
      //         picture:
      //           "54b2adfbea493b89bdffb6786e8e65579305b0ba13eb39c82b18d7ccbe48cd7b",
      //         nickname: "Ma Rion",
      //         lastname: "Rion",
      //         isFederated: true,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 0,
      //           followee: 0,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#C7EA77",
      //       booksCover: [
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070584925",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "3ae4f0f563eb00b0c486134b7ff77185166b995dc30ee7289f3f3e33129566f5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253029496",
      //           title: "Un sac de billes",
      //           authors: ["Joseph Joffo"],
      //           image:
      //             "a3b4c723ea187a3c5cc625a98f027e49fa084b2268d732d41c5622c1792ad379",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782709624930",
      //           title: "Da Vinci code",
      //           authors: ["Dan Brown"],
      //           image:
      //             "de4e4a669ccf08237edefa8fc6ffeba69cd83ba4d73274e408bfcc2e37b984d9",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782840116462",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "24b6be674d95b2ec09836a1c3fafcce3219bc74e2054c18a150b29aef2f04693",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070584628",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "413b227051c993b9b9ee387e05cbc4743cdcc690716e1114644b77d334479a17",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1636286266807,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1636286214920,
      //       booksCount: 6,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Livres lus ",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-6BBCE654D3A64716A27117E72E664C2D-A5CC9BE7D44C4FFDBFEB80B295343BCA",
      //       owner: {
      //         id: "6BBCE654D3A64716A27117E72E664C2D",
      //         picture:
      //           "22e5216786f6df57fa29ddfe863b24e09c97c407b1d094db6c6950a93d9ffb25",
      //         firstname: "Julia",
      //         nickname: "Julia.e",
      //         lastname: "",
      //         isFederated: true,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 12,
      //           followee: 12,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#F2D60F",
      //       booksCover: [
      //         {
      //           id: "9782070661985",
      //           title: "La Passe-miroir T.2 ; les disparus du Clairdelune",
      //           authors: ["Christelle Dabos"],
      //           image:
      //             "97fbd307d551b8d8fcf379447a1e3120dc20a74babab0a55a1e58c9b3187b63e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791028105457",
      //           title:
      //             "Altered carbon ; le cycle de Takeshi Kovacs T.1 ; carbone modifié",
      //           authors: ["Richard Morgan"],
      //           image:
      //             "de3eae6f3ddfb699171558a76969823ba1caf03bd741755776bae697d916e13b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070582617",
      //           title: "La Passe-miroir T.1 ; les fiancés de l'hiver",
      //           authors: ["Christelle Dabos"],
      //           image:
      //             "d2b8337de2f48ebfeedd91833f8a157e6c4dbe8e73a7d2eedee5a0908f3c69c1",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290098493",
      //           title: "Outlander t.2 ; le talisman",
      //           authors: ["Diana Gabaldon"],
      //           image:
      //             "be01ebd514c8c4f5fba2e4c14c68952885e13e834c4cd9ad9bb97d6ec9f46e78",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290099605",
      //           title: "Outlander t.3 ; le voyage",
      //           authors: ["Diana Gabaldon"],
      //           image:
      //             "c1928935886ec12d073a928b8310c64661e762f566efd0dd87936f5776aef50d",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070612369",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "2c789a58f069d3f25ee8356e39704c83dbd7cdcc4b4975952432c91f751844b7",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253194606",
      //           title: "After T.3 ; after we fell",
      //           authors: ["Anna Todd"],
      //           image:
      //             "2fd3b9abc4e9380af447e3e04a35e071fbd4e6870e34249d9d32d9340501dac2",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253194590",
      //           title: "After T.2 ; after we collided",
      //           authors: ["Anna Todd"],
      //           image:
      //             "5e801ec1599d9858d77ea5d203ff102fd12b9a612cf65d5a42406ca6dccdff02",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253237587",
      //           title: "After T.1",
      //           authors: ["Anna Todd"],
      //           image:
      //             "50ee1a0a416e4585405aa751c237ac8db4a1d1ba171175c080f22d999839fce6",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253183969",
      //           title: "Joyland",
      //           authors: ["Stephen King"],
      //           image:
      //             "cb0ca2b148e812fb27d5ab97d03900d138ec3c44f707eee38b67f019a8117fd9",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1636197259229,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1636197251412,
      //       booksCount: 11,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Fantaisie / jeunesse ",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-2A51EC59E86240E28A69E9AC0C77DBE5-4367327DBAC343FEA27D71088D1311BB",
      //       owner: {
      //         id: "2A51EC59E86240E28A69E9AC0C77DBE5",
      //         firstname: "",
      //         nickname: "Lisebrtn",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 1,
      //           followee: 2,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#FC7E87",
      //       booksCover: [
      //         {
      //           id: "9782253101673",
      //           title: "à la recherche d'Alice Love",
      //           authors: ["Liane Moriarty"],
      //           image:
      //             "cf5a8575ec79418f14993085ec0930a34431fdfe0755ae4dbaee02befd81d6dd",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253934097",
      //           title: "La tresse",
      //           authors: ["Laetitia Colombani"],
      //           image:
      //             "184a85066dc979fa10db2d50562816c05045d92b9933337e4ed0dea168f03cae",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782211238465",
      //           title: "Les misérables",
      //           authors: ["Victor Hugo"],
      //           image:
      //             "1dd51498cb87d9166999851aa9c876ee1e997265f98a8b3b2bc69bcde85ca8a5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070106462",
      //           title: "L'étranger",
      //           authors: ["Albert Camus"],
      //           image:
      //             "4eb97d311222b65e14ab44caa18fc2d9dd556e4bae7b60d7c1bd55dfd4af417d",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266195584",
      //           title: "Bonjour tristesse",
      //           authors: ["Françoise Sagan"],
      //           image:
      //             "f9030d77cdc127ea16c48b5bafb683aab495516d040f06ae3e047d00c3fa104b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782355363245",
      //           title: "Tout le bleu du ciel",
      //           authors: ["Mélissa Da Costa"],
      //           image:
      //             "9645eda408cb6c9b797992ea4efaf605fea42e4406f1d1be390f8fb26a68307e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253071167",
      //           title: "Les oubliés du dimanche",
      //           authors: ["Valérie Perrin"],
      //           image:
      //             "0c88bb3170ce786e73cb01c3f8a25c289084eacffa00761bb2c93ead1dc01f25",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782754084703",
      //           title: "Il faut que je vous parle",
      //           authors: ["Blanche Gardin"],
      //           image:
      //             "ce05c89062a30ded4f1889f561d68e0b6cc488ce05d42abd42f8bfeddd2ada8b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266219150",
      //           title: "Les dieux voyagent toujours incognito",
      //           authors: ["Laurent Gounelle"],
      //           image:
      //             "cc19b3485b94711fca59c53806bee6521758b497a621d4fad938bf9e2e2a1d5b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782290240182",
      //           title: "Kilomètre zéro",
      //           authors: ["Ankaoua Maud"],
      //           image:
      //             "21afcf0e8c3060771b18268cd586015242b67ef00af0a7eadf180e257ff1b885",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253045281",
      //           title: "Toute la lumière que nous ne pouvons voir",
      //           authors: ["Anthony Doerr"],
      //           image:
      //             "d3dbd57a55fc5a3b7b983808753283921661155121270dd2f5ea11201e35395f",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782226445889",
      //           title: "Amours solitaires ; une petite éternité",
      //           authors: ["Morgane Ortin"],
      //           image:
      //             "9e5d20509932b7820d4134e389c6255db95d774ea5ecd096dd05249601243412",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782863744437",
      //           title: "30 ans (10 ans de thérapie)",
      //           authors: ["Nora Hamzawi"],
      //           image:
      //             "35f6c610d0bb9c4ca228b3de9f245d5cc7a3ea817fe8b6afac1e3ce56f866dfd",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782246809043",
      //           title: "L'appel du coucou",
      //           authors: ["Robert Galbraith"],
      //           image:
      //             "8ef945b8e19dd85b6bf59c19c27c11d81001d0be57c1b52ec879c727533229ef",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070464791",
      //           title: "Auprès de moi toujours",
      //           authors: ["Kazuo Ishiguro"],
      //           image:
      //             "c6ec6c550436924b589221604e05a4005ce7322667da092d557f419c70925793",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635895182819,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1610234359717,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Roman",
      //       booksCount: 66,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-3AE082AEA45247C5B0417DA02D304ACC-6F45EFE55DA14CCDB23E10702CA5D53F",
      //       owner: {
      //         id: "3AE082AEA45247C5B0417DA02D304ACC",
      //         firstname: "Sirine",
      //         picture:
      //           "e94ac2f9bbd4cc5b74eed46ce1e6164cf65a1de0b7a5a34c5d705cb98a67d57a",
      //         nickname: "sirine.books",
      //         lastname: "Mendes",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 3,
      //           followee: 4,
      //         },
      //       },
      //       collabCount: 0,
      //       image:
      //         "b0160fbf5edbb74bc68ae031011f24d12e4950434862da1b39c27267314909b7",
      //       followCount: 0,
      //       color: "#D1DA8E",
      //       booksCover: [
      //         {
      //           id: "9781595325945",
      //           title: "Diabolo Vol. 3",
      //           image:
      //             "a67c0040229be783edc2198f88ea7f6a9121ce31ac121957ba03d8e6720ddc7f",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9781595322333",
      //           title: "Diabolo (Diabolo), Vol. 2 (Diabolo)",
      //           image:
      //             "fa4a239f9659aa19c30b2a49023367d7873a259b64342896c55a58998aa5daaf",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253134824",
      //           title: "The walking dead t.1 ; l'ascension du gouverneur",
      //           authors: ["Robert Kirkman", "Jay Bonansinga"],
      //           image:
      //             "005303e4b8f174d8ffa5c5a9a2f000ed79ef4acdef5b9e277ecbc65007047eb4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176503",
      //           title: "Cinquante nuances T.1 ; cinquante nuances de Grey",
      //           authors: ["E. L. James"],
      //           image:
      //             "b5df53b65917c763119e3c4836c64ca1d57779adba3f24e8392b79a54b6b6ccb",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176510",
      //           title: "Cinquante nuances T.2 ; cinquante nuances plus sombres",
      //           authors: ["E. L. James"],
      //           image:
      //             "0091b09441f0ca2a651d9b6436d86e5419a83abf98c27d8957a5dd7710982da5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176527",
      //           title: "Cinquante nuances T.3 ; cinquante nuances plus claires",
      //           authors: ["E. L. James"],
      //           image:
      //             "72bd2798efcc50e1dbec95e18cd02da736f9e02fda7f36efca3e48d9faee41ae",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782809489040",
      //           title:
      //             "Marvel: les grandes batailles 02 - les gardiens de la galaxie vs thanos",
      //           authors: [],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782017094463",
      //           title: "Loki - les racines du mal",
      //           authors: ["Mackenzi Lee"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811600174",
      //           title: "Fairy Tail T.5",
      //           authors: ["Hiro Mashima"],
      //           image:
      //             "b442ffe0594cb1b3864e2a69c2beeafb848241df44d7cfb28294080747990d66",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782845999879",
      //           title: "Fairy Tail T.4",
      //           authors: ["Hiro Mashima"],
      //           image:
      //             "417e9983ed9d401a739d62c96e9dab249c852d99b31aa1751f6f8e6f9cc763fb",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782298034516",
      //           title: "L'appel du sang",
      //           image:
      //             "d5cc7feea272b138e4fd3ee56c76ecd14f532c76b1453239143cdb2b3ae3b886",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782809499315",
      //           title: "Hawkeye T.1 ; ma vie est une arme",
      //           authors: ["Matt Fraction", "David Aja"],
      //           image:
      //             "9bb320f0bef6b5282cd2c31defe7567bc4d5c35eb73bb1ac20a07e7748ed43a4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811611699",
      //           title: "L'attaque des titans T.1",
      //           authors: ["Hajime Isayama"],
      //           image:
      //             "6fa089320704e329937b9b1a8fdeffa5e2b8c2107461c5fb62cf81622eeb569b",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782809489118",
      //           title: "Captain America vs Crane Rouge",
      //           authors: ["Collectif"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782845999459",
      //           title: "Fairy Tail T.2",
      //           authors: ["Hiro Mashima"],
      //           image:
      //             "b69d783d1ae9a7ed0a18f897d844f972e3be2888c5f74582fa19d9c230995349",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782013974035",
      //           title: "The Originals t.1 ; l'ascension",
      //           authors: ["Julie Plec"],
      //           image:
      //             "39d48add33f817c510410c92fcbf2e384a49cab4c63172257f52379831762147",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782019109950",
      //           title: "Percy Jackson T.1 ; le voleur de foudre",
      //           authors: ["Rick Riordan"],
      //           image:
      //             "4206532e754971c71e9af0c04efaadf5bfd226bdb5b84d9e39aed1b6ed77b1e5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635859064533,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1615060783884,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Film/série/manga",
      //       booksCount: 34,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-3AE082AEA45247C5B0417DA02D304ACC-6B78D68FCBDA45138607A7A5E17D9D3A",
      //       owner: {
      //         id: "3AE082AEA45247C5B0417DA02D304ACC",
      //         firstname: "Sirine",
      //         picture:
      //           "e94ac2f9bbd4cc5b74eed46ce1e6164cf65a1de0b7a5a34c5d705cb98a67d57a",
      //         nickname: "sirine.books",
      //         lastname: "Mendes",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 3,
      //           followee: 4,
      //         },
      //       },
      //       collabCount: 0,
      //       image:
      //         "b0160fbf5edbb74bc68ae031011f24d12e4950434862da1b39c27267314909b7",
      //       followCount: 0,
      //       color: "#D1C8B3",
      //       booksCover: [
      //         {
      //           id: "9782013974035",
      //           title: "The Originals t.1 ; l'ascension",
      //           authors: ["Julie Plec"],
      //           image:
      //             "39d48add33f817c510410c92fcbf2e384a49cab4c63172257f52379831762147",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782371022966",
      //           title: "My Dear F***ing Prince",
      //           authors: ["MCQUISTON CASEY"],
      //           image:
      //             "1942b1ae13d6f987cb8085fec5180e4a43a75b10d3d0ea0947da788e559fbf12",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253134824",
      //           title: "The walking dead t.1 ; l'ascension du gouverneur",
      //           authors: ["Robert Kirkman", "Jay Bonansinga"],
      //           image:
      //             "005303e4b8f174d8ffa5c5a9a2f000ed79ef4acdef5b9e277ecbc65007047eb4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176503",
      //           title: "Cinquante nuances T.1 ; cinquante nuances de Grey",
      //           authors: ["E. L. James"],
      //           image:
      //             "b5df53b65917c763119e3c4836c64ca1d57779adba3f24e8392b79a54b6b6ccb",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176510",
      //           title: "Cinquante nuances T.2 ; cinquante nuances plus sombres",
      //           authors: ["E. L. James"],
      //           image:
      //             "0091b09441f0ca2a651d9b6436d86e5419a83abf98c27d8957a5dd7710982da5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782253176527",
      //           title: "Cinquante nuances T.3 ; cinquante nuances plus claires",
      //           authors: ["E. L. James"],
      //           image:
      //             "72bd2798efcc50e1dbec95e18cd02da736f9e02fda7f36efca3e48d9faee41ae",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9780349134284",
      //           title: "Everything I Never Told You",
      //           authors: ["Celeste Ng"],
      //           image:
      //             "c6529e87f725b25a3c63b9fe2006bab95999c66bd85ce076a6a3cae813c80372",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782755686814",
      //           title: "Landon & Shay T.1",
      //           authors: ["Brittainy C. Cherry"],
      //           image:
      //             "51420d3c4d1257cbebee60d8034ad8e68e32eeb1709adc9cdd5424a4835fa13e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782081208209",
      //           title: "Le Cid",
      //           authors: ["Pierre Corneille"],
      //           image:
      //             "dc7b3c3d9b633b8baa923a1030345141e04aac5cf5ccd2d7c6b33383fad29e5e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266268554",
      //           title: "Miracle morning",
      //           authors: ["Hal Elrod"],
      //           image:
      //             "b28acc0d5570e99b236e08fec32ded1803d143a6fdb3c97eb5348d2360ccc14e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070425525",
      //           title: "La marque de la bête ; autres nouvelles",
      //           authors: ["Rudyard Kipling"],
      //           image:
      //             "e6307f7b717e13d06405a6724f0c746408ffc606a7874b1b4d5a14d428d9a11f",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266122696",
      //           title: "Où es-tu ?",
      //           authors: ["Marc Levy"],
      //           image:
      //             "b11f350e29864f78b6c46be5a577b3e6c1821f595dc729c45122cdd7c687bdb1",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782218991363",
      //           title: "L'Odyssée",
      //           authors: ["Homère"],
      //           image:
      //             "98eb1765c4b773e034a6ac1badb567063c0182017326c80b113719a22c753fae",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221193457",
      //           title: "Girlhood",
      //           authors: ["Cat Clarke"],
      //           image:
      //             "8c4c8324892a1fa556a3aa7b52f7bf78f3c447d0649f768694f65f6cb9fb41f8",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782352876526",
      //           title: "Les liaisons dangereuses",
      //           authors: ["Pierre Choderlos De Laclos"],
      //           image:
      //             "612ff494de64373e4ae4d43bf98ab1b93712ae4dbf1e68fd0d5b1413348dcfa7",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782017094463",
      //           title: "Loki - les racines du mal",
      //           authors: ["Mackenzi Lee"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782811600174",
      //           title: "Fairy Tail T.5",
      //           authors: ["Hiro Mashima"],
      //           image:
      //             "b442ffe0594cb1b3864e2a69c2beeafb848241df44d7cfb28294080747990d66",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782298034516",
      //           title: "L'appel du sang",
      //           image:
      //             "d5cc7feea272b138e4fd3ee56c76ecd14f532c76b1453239143cdb2b3ae3b886",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782809489118",
      //           title: "Captain America vs Crane Rouge",
      //           authors: ["Collectif"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782019109950",
      //           title: "Percy Jackson T.1 ; le voleur de foudre",
      //           authors: ["Rick Riordan"],
      //           image:
      //             "4206532e754971c71e9af0c04efaadf5bfd226bdb5b84d9e39aed1b6ed77b1e5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635858951943,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1615060349929,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Ma pal ",
      //       booksCount: 57,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-5E9372E9C62B4C47BAC776F9DF076D7B-B53D12E76C514ED081586733407FD5F2",
      //       owner: {
      //         id: "5E9372E9C62B4C47BAC776F9DF076D7B",
      //         firstname: "",
      //         picture:
      //           "74852c19c7d79dd6229b58ef3fbfc6f34e786c6d437a6e2fa64614ea653ee4e3",
      //         nickname: "Lapetitejajouse",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 0,
      //           followee: 0,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#89DA8E",
      //       booksCover: [
      //         {
      //           id: "9782070649693",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "82ae599d78962b311b9a692416c0b737b56a7230aa6c7a7e828e59daaf6b108d",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791032402627",
      //           title: "La pire soirée de ta vie",
      //           authors: ["Andy Rowski"],
      //           image:
      //             "2fd76f8f190318d3a9c8fc6ad6f13393f14bf18f56e33fa713d382d65900287d",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624911",
      //           title:
      //             "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782075074209",
      //           title: "Harry Potter et l'enfant maudit ; parties I et II",
      //           authors: ["J. K. Rowling", "John Tiffany", "Jack Thorne"],
      //           image:
      //             "a760de2e58fef4312ebb0d56a798eaf3fa4e09fa02f6b54f50d8d9610983cfc5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782330096854",
      //           title: "Boom",
      //           authors: ["Julien Dufresne-Lamy"],
      //           image:
      //             "e8fe015723c0f98363b5e876ba9e9a06220758ecd7804f8ede7ed25ff3c6a087",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635803479109,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1635256459964,
      //       booksCount: 6,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Roman",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-27EBC8133BC84F64B966237A8A6092E0-4EE8B80A18AF4514B370E0CE3809CCD3",
      //       owner: {
      //         id: "27EBC8133BC84F64B966237A8A6092E0",
      //         firstname: "",
      //         picture:
      //           "9a3ef88b083c9920054b8f6df0171fd375d7c6fccc13a3529ebcf9cfd0addbd7",
      //         nickname: "Amooniac",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 0,
      //           followee: 0,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#D59AED",
      //       booksCover: [
      //         {
      //           id: "9782266293778",
      //           title: "U4 ; Yannis",
      //           authors: ["Florence Hinckel"],
      //           image:
      //             "b729fa7489d9aecd4c939464d22841dd26e3b94662de7f054922400a64fed495",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782266293792",
      //           title: "U4 : Stéphane",
      //           authors: ["Vincent Villeminot"],
      //           image:
      //             "089221fff50d778d005107656568078416273a8506c97397ce6b1d005dd8121e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782226453587",
      //           title: "Coraline (ed 2020 illustre)",
      //           authors: ["Gaiman/Neyret"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "Tout me romans, album et livre illustré ",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635716609651,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1635716317229,
      //       booksCount: 5,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Album/roman",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-50E50B0DF2AB4836BD599D4B74C6B9B7-22CE9E8F9010430D9EEE6950CA8D0264",
      //       owner: {
      //         id: "50E50B0DF2AB4836BD599D4B74C6B9B7",
      //         firstname: "",
      //         picture:
      //           "3888bdd72805f8ada0ed0350ebac0cef74218adffac638034b33a0ee5a0e6dc8",
      //         nickname: "Sarah Cam",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 13,
      //           followee: 20,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 1,
      //       color: "#CBD7BD",
      //       booksCover: [
      //         {
      //           id: "9782221252314",
      //           title: "Dune - tome 3 les enfants de dune - ne 2021 - vol03",
      //           authors: ["Frank Herbert"],
      //           image: "",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624553",
      //           title: "Harry Potter T.4 ; Harry Potter et la coupe de feu",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "427948dc15c55c41baf894c6428a563e8f8f235dec7a08fc1f442b61f0c55724",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624546",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "7211a7590185c6ec13befd7b294bf98de708b69e80a8bead346b376aa76269da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624911",
      //           title:
      //             "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624904",
      //           title:
      //             "Harry Potter T.6 ; Harry Potter et le prince de sang-mêlé",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "edc9a33ef83e7e4ccc593641f62f47daa97ec9033989f0c8b80cea4939e2ba89",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624560",
      //           title: "Harry Potter T.5 ; Harry Potter et l'ordre du phénix",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ed85e918875e97ca265b6ac1f28d852d1646a96945ae3a4da476b36e9eeb7ec4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221252307",
      //           title: "Le messie de dune",
      //           authors: ["Frank Herbert"],
      //           image:
      //             "b39c1cbeb6ef9671cbedc0105d97bbea3aeeb07cf151546a48ae6d72e1bb3b17",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782221252055",
      //           title: "Dune",
      //           authors: ["Frank Herbert"],
      //           image:
      //             "86990dbad6cdfc42089def0f91d38cdd5c5c8e18cfaf15c62300ff6560592110",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070650644",
      //           title: "Vendredi ou la vie sauvage",
      //           authors: ["Michel Tournier"],
      //           image:
      //             "e6c72c3824a47c2a0034df0b3d1483f55b53a947d1df16064f7249dc1bab64d0",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9791028111717",
      //           title: "Le sorceleur. Volume 3, Le sang des elfes",
      //           authors: ["Andrzej Sapkowski"],
      //           image:
      //             "8ddc05690b05e968494e38f0121ea7cbf757e5496be7726c437928b326277973",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070695768",
      //           title: "Le combat d'hiver",
      //           authors: ["Jean-Claude Mourlevat"],
      //           image:
      //             "378fe60bee47032c8989f539e7c452b6a5a0097c9a17aa9194d73ce5e24bc01d",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782740410936",
      //           title: "Graines de futurs ; anthologie",
      //           authors: [
      //             "Robert Belfiore",
      //             "Christian Grenier",
      //             "Joëlle Wintrebert",
      //             "Jean-Pierre Hubert",
      //             "A Guero",
      //             "G Guero",
      //             "Alain Grousset",
      //           ],
      //           image:
      //             "65e45f86a660d267e744896fc9b3118a3536f5bda2231e71117f733903e658aa",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070629459",
      //           title: "Tobie Lolness t.1 ; la vie suspendue",
      //           authors: ["Timothée  de Fombelle"],
      //           image:
      //             "f71b3d8534508bf427336106bc9247f88872209bbb66c4b697e0b63e91f92a64",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070661985",
      //           title: "La Passe-miroir T.2 ; les disparus du Clairdelune",
      //           authors: ["Christelle Dabos"],
      //           image:
      //             "97fbd307d551b8d8fcf379447a1e3120dc20a74babab0a55a1e58c9b3187b63e",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635612522372,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1619241624609,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Littérature jeunesse",
      //       booksCount: 30,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 1,
      //   },
      //   {
      //       id: "WORK_CUSTOM-8BB6336393FC49D19692CCA956C79B9A-14595AE21F6B4A65B5FFC65741AB2343",
      //       owner: {
      //         id: "8BB6336393FC49D19692CCA956C79B9A",
      //         firstname: "",
      //         picture:
      //           "798b1ef4a54fc6a099b950c050c2d6797fbdd7a192f09a64ad874cfb0483aeb7",
      //         nickname: "Vivie13",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 3,
      //           followee: 1,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#BB99FD",
      //       booksCover: [
      //         {
      //           id: "9782075163057",
      //           title: "Jack et la grande aventure du cochon de Noël",
      //           authors: ["J. K. Rowling", "Jim Field"],
      //           image:
      //             "cdaae864fd92f44bfc1b6dd097fa8242932b7e1e8078666b29db882f8136884f",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782915320572",
      //           title: "Tout l'univers magique de Harry Potter",
      //           authors: ["Stéphanie Chica"],
      //           image:
      //             "98f38db3f1ea3adb63f52512e5fc1e15e6889ccbe6ce17970ba88642f92051ba",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624911",
      //           title:
      //             "Harry Potter T.7 ; Harry Potter et les reliques de la mort",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "68d95eee6fbaa8001f97ef925023b8b53b35915f05f33bffc49da320881f2de3",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624553",
      //           title: "Harry Potter T.4 ; Harry Potter et la coupe de feu",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "427948dc15c55c41baf894c6428a563e8f8f235dec7a08fc1f442b61f0c55724",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624546",
      //           title:
      //             "Harry Potter T.3 ; Harry Potter et le prisonnier d'Azkaban",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "7211a7590185c6ec13befd7b294bf98de708b69e80a8bead346b376aa76269da",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782075074209",
      //           title: "Harry Potter et l'enfant maudit ; parties I et II",
      //           authors: ["J. K. Rowling", "John Tiffany", "Jack Thorne"],
      //           image:
      //             "a760de2e58fef4312ebb0d56a798eaf3fa4e09fa02f6b54f50d8d9610983cfc5",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624904",
      //           title:
      //             "Harry Potter T.6 ; Harry Potter et le prince de sang-mêlé",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "edc9a33ef83e7e4ccc593641f62f47daa97ec9033989f0c8b80cea4939e2ba89",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624560",
      //           title: "Harry Potter T.5 ; Harry Potter et l'ordre du phénix",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ed85e918875e97ca265b6ac1f28d852d1646a96945ae3a4da476b36e9eeb7ec4",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782075150552",
      //           title: "L'Ickabog",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "d7b602dfefc896881911b13a17ad06a62bf2bfca5ca594266b5a0c5bbdb66818",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "Fantasy",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635595664495,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1607163907984,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "J.K Rowling",
      //       booksCount: 11,
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
      //   {
      //       id: "WORK_CUSTOM-DC7BE1F500D64847B8C0C82C9DC43880-776D38663BB7472894AE5939351EB085",
      //       owner: {
      //         id: "DC7BE1F500D64847B8C0C82C9DC43880",
      //         firstname: "",
      //         picture:
      //           "5d0e35034cda0a8a35620c183c2c1e9f5acf92ada6730824c524529e43fa8349",
      //         nickname: "Mlle.lys",
      //         lastname: "",
      //         isFederated: false,
      //         canTalk: true,
      //         friendStatus: "none",
      //         isFollower: false,
      //         isFollowed: false,
      //         more: {
      //           follower: 7,
      //           followee: 5,
      //         },
      //       },
      //       collabCount: 0,
      //       image: "",
      //       followCount: 0,
      //       color: "#D59AED",
      //       booksCover: [
      //         {
      //           id: "9782070624539",
      //           title:
      //             "Harry Potter T.2 ; Harry Potter et la chambre des secrets",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "501c50f25925dbe4a51052a404cb7f7637fb0d45858a21922429159e19ba9d4c",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //         {
      //           id: "9782070624522",
      //           title: "Harry Potter T.1 ; Harry Potter à l'école des sorciers",
      //           authors: ["J. K. Rowling"],
      //           image:
      //             "ffde58d39b7bce620042023be64037d06f25fa060e04595ee54724d842a51687",
      //           clilObj: {
      //             id: "999999",
      //             libelle: "SANS CATÉGORIE",
      //             color: "#979797",
      //             topId: null,
      //           },
      //           more: {},
      //         },
      //       ],
      //       collaborative: false,
      //       icon: "",
      //       description: "",
      //       privacy: "PUBLIC",
      //       updatedOn: 1635460786212,
      //       internalType: "CUSTOM",
      //       type: "CLASSIC",
      //       createdOn: 1635459398446,
      //       booksCount: 2,
      //       deleted: false,
      //       booksLimit: -1,
      //       name: "Fantastique",
      //       isFollowed: false,
      //       isPinned: false,
      //       isLiked: false,
      //       hasCommented: false,
      //       commCount: 0,
      //       likeCount: 0,
      //   },
    ],
  },
};
