import ReadMore from "./ReadMore";
//Component affichant le résumé d'un livre
const ResumePanel = (props) => {
  const { description } = props;
  return (
    <div className="containerResume">
      <h2 className="resumeTitlePolice">Résumé</h2>
      <ReadMore content={description} />
    </div>
  );
};

export default ResumePanel;
