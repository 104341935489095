import helpers from "../utils/APIHelper";
import { Helmet } from 'react-helmet-async';
import model from "../models/model";
import React from "react";
import Comment from "../components/Comment";
import Lottie from "react-lottie";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK.json";
import Reaction from "../components/Reaction";
import BackGroundBook from "../components/BackGroundBook";
import WriteComment from "../components/WriteComment";
import BottomBarUser from "../components/BottomBarUser";
import TopBar from "../components/TopBar";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

//Composant regroupant tous les éléments de la page avis
export default class CommentScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comment: model.comment,
    };
  }

  async componentDidMount() {
    const pathName = window.location.pathname.split("/");
    const id = pathName[3];
    const res = await helpers.fetchComment(id);
    if (res.message === "NotExist") {
      alert("Le livre n'existe pas");
    } else {
      this.setState({ comment: res, loading: false });
    }
  }

  render = () => {
    const { comment, book } = this.state.comment;
    const { loading } = this.state;
    const { toggleModal } = this.props;

    const sortedComments = comment.comments.sort(
      (a, b) => a.updatedOn - b.updatedOn
    );

    const commentAuthor = {
      nickname: comment.author.nickname ? comment.author.nickname : "Gleepher",
      picture: comment.author.picture,
    };

    const commentScreenTitle = `L'avis de ${commentAuthor.nickname}`;

    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <TopBar toggleModal={toggleModal} title={commentScreenTitle} />
        <div className="commentScreen-header">
          <p className="header-title">{commentScreenTitle}</p>
        </div>
        <div className="backgroundGrey">
          <div className="fixWidth">
            <div className="backgroundContent">
              <a
                href={"/share/book/" + book.id}
                style={{ textDecoration: "none" }}
              >
                <BackGroundBook book={book} loading={loading} />
              </a>
              {loading ? (
                <div className="commentScreen-comment-loading">
                  <Lottie options={defaultOptions} width={50} />
                </div>
              ) : (
                <div>
                  <Comment
                    comment={comment}
                    loading={loading}
                    toggleModal={toggleModal}
                  />
                  {sortedComments.map((reaction) => (
                    <Reaction
                      key={reaction.id}
                      reaction={reaction}
                      toggleModal={toggleModal}
                    />
                  ))}
                  <WriteComment toggleModal={toggleModal} />
                </div>
              )}
            </div>
          </div>
          <BottomBarUser
            user={commentAuthor}
            showingOnChange={toggleModal}
            isComment
          />
        </div>
      </>
    );
  };
}
