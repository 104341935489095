import React from "react";
import cst from "./constant";
import playStore from "../images/playStore.png";
import appleStore from "../images/appleStore.png";

function pluralize(count, singleText, multipleText, zeroText?) {
  if (count === 1) {
    return singleText;
  } else if (count > 1) {
    return multipleText || singleText;
  }
  return zeroText || singleText;
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

//Fonction pour utiliser la roulette des souris afin de naviguer dans la liste
function onWheel(apiObj, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function isRetina() {
  return (
    ((window.matchMedia &&
      (window.matchMedia(
        "only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)"
      ).matches ||
        window.matchMedia(
          "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)"
        ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio >= 2)) &&
    /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  );
}

//Composant gérant le render des boutons de download selon l'écran
function DownloadApp(props) {
  const { isMobile, show, setShow, showingOnChange } = props;
  if (isMobile) {
    return (
      <div className="bottombar-openapp-container">
        <a href={cst.DEEPLINK} style={{ textDecoration: "none" }}>
          <div className="downloadBottomBarMin">
            <span className="downloadPoliceBottomMin">
              ouvrir l'application
            </span>
          </div>
        </a>
        {show ? (
          <div className="plusTardBottomBarContainer">
            <span
              className="plusTardBottomBarPolice"
              onClick={() => setShow(false)}
              style={{ cursor: "pointer" }}
            >
              <b>Plus tard</b>
            </span>
          </div>
        ) : (
          <div style={{ paddingBottom: 19 }} />
        )}
      </div>
    );
  } else {
    return (
      <div onClick={() => showingOnChange()} style={{ cursor: "pointer" }}>
        <img src={playStore} alt="playStore" className="playStore" />
        <img src={appleStore} alt="appleStore" className="appleStore" />
      </div>
    );
  }
}

function formatUrl(bookId, authors, title, returnCombinedStr = false) {
  const maxLengthUrl = 300;

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function formatString(str) {
    str = removeAccents(str);
    str = str.toLowerCase().replace(/\.|\s/g, "-");
    str = str.replace(/[^a-z0-9-]/g, ""); // remove non-alphanumeric characters except hyphens
    str = str.replace(/-+/g, "-"); // replace multiple consecutive hyphens with a single one
    str = str.replace(/^-|-$/g, ""); // trim hyphens at the start and end
    return str;
  }

  let formattedAuthors = "";

  // authors is supposed to be an array, if it's not, we treat it as an empty array
  authors = Array.isArray(authors) ? authors : [];
  let charCount = 0;

  for (let i = 0; i < authors.length; i++) {
    let author = authors[i];
    if (author && author !== "Collectif") {
      let formattedAuthor = formatString(author);
      if (charCount + formattedAuthor.length <= 150) {
        charCount += formattedAuthor.length;
        formattedAuthors += formattedAuthor + "-";
      } else {
        break;
      }
    }
  }

  let formattedTitle = formatString(title);

  let combinedStr = `${formattedAuthors}${formattedTitle}`;

  // Trim if the combined string has exceeded 150 characters
  if (combinedStr.length > maxLengthUrl) {
    combinedStr = combinedStr.slice(0, maxLengthUrl);
    let lastIndex = combinedStr.lastIndexOf("-");
    // If the last character is a hyphen or the word is cut in half, remove the hyphen and the last word
    if (
      lastIndex === combinedStr.length - 1 ||
      combinedStr[lastIndex + 1] !== "-"
    ) {
      combinedStr = combinedStr.slice(0, lastIndex);
    }
  }
  if (returnCombinedStr) {
    return combinedStr;
  }

  let formattedUrl = `/share/book/${bookId}/${combinedStr}`;
  return formattedUrl;
}

export {
  pluralize,
  getMobileOperatingSystem,
  onWheel,
  isRetina,
  DownloadApp,
  formatUrl
};
