import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import React from "react";
import iconAuthor from "../images/iconAuthor.svg";
import iconAuthorMin from "../images/iconAuthorMin.svg";
import logo from "../images/logo.svg";
import DefaultCover from "./DefaultCover";
import InfoBook from "./InfoBook";

const icon = {
  mini: {
    author: iconAuthorMin,
  },
  normal: {
    author: iconAuthor,
  },
};

//Composant du Header de BookScreen avec le background
const BackGroundBookFull = (props) => {
  const { book, canonicals } = props;
  const { image, title, edition, publicationYear, page, clilObj } = book;
  const cover = cst.WORK_PICTURE + image + cst.FIN_LIEN;

  return (
    <div className="backgroundTopBook">
      <div className="container">
        <div
          className="blurCover"
          style={image ? { backgroundImage: `url(${cover})` } : null}
        />
      </div>
      <div className="containerTopBook">
        <div className="containerBookCover">
          {image ? (
            <img src={cover} alt="Shelf Cover" className="sizeBookCover" />
          ) : (
            <DefaultCover title={title} style={"sizeBookCover"} />
          )}
        </div>
        <div className="containerInfoBook">
          <h1 className="titleShelf" style={{ fontStyle: "italic" }}>
            <a href={`/share/book/${canonicals}`} className="titleShelfLink" style={{ color: "white", textDecoration: "none" }}>
              {title}
            </a>
          </h1>
          <InfoBook
            className="infoBookPolice"
            edition={edition}
            publicationYear={publicationYear}
            page={page}
          />
          <div
            style={{ backgroundColor: clilObj.color }}
            className="clilContainer"
          >
            <img
              src={cst.CLIL_ICON[clilObj.color]}
              alt="Author Icon"
              className="bookScreen-iconAuthor"
            />
            <span className="typeBookPolice" style={{ paddingLeft: 6 }}>
              {clilObj.libelle}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

//Composant d'attente de remplissage du header de BookScreen
const LoadingBackGroundBook = (props) => {
  const { screen } = props;
  return (
    <div className="backgroundTopBook">
      <div className="containerTopBook">
        <div className="containerBookCover">
          <div
            className="sizeBookCover sizeBookCover-loading"
            style={{ background: "#252525" }}
          >
            <img
              src={logo}
              alt="Default Cover"
              className="bookscreen-cover-loading"
            />
          </div>
        </div>
        <div className="containerInfoBook ">
          <div>
            <div className="titleBook-loading-up" />
            <div className="titleBook-loading-down" />
          </div>
          <span className="backgroundbook-police-loading " />
          <div
            style={{ backgroundColor: "#D1C8B3" }}
            className="clilContainer-loading"
          >
            <img src={icon[screen]["author"]} alt="Author Icon" />
            <span
              style={{ marginLeft: 6, color: "#D1C8B3" }}
              className="typeBookPolice"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

//Component qui affiche le background de la fiche livre selon le chargement de la page
const BackGroundBook = (props) => {
  const { loading, book, canonicals } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  if (loading) {
    return <LoadingBackGroundBook screen={screen} />;
  } else {
    return <BackGroundBookFull book={book} canonicals={canonicals} />;
  }
};

export default BackGroundBook;
