import cst from "../utils/constant";
import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import {formatUrl, onWheel} from "../utils/functions";
import useDrag from "../utils/useDrag";
import DefaultCover from "./DefaultCover";

const style = {
  default: {
    allCardContainer: "bookscreen-similarbook-allcard-container",
    listBookContainer: "bookscreen-similarbook-listbook-container",
    bookCover: "bookscreen-similarbook-cover-style",
  },
  shelf: {
    allCardContainer: "bookscreen-shelf-allcard-container",
    listBookContainer: "bookscreen-shelf-listbook-container",
    bookCover: "bookscreen-shelf-cover-style",
  },
};

//Composant render une liste de livre
function WorkHorizontalList(props) {
  let items = props.works;
  const size = props.size;

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragStop} style={{ cursor: "pointer" }}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        <div className={style[size].allCardContainer} />
        {items.map((work) => (
          <Card
            itemId={work.id} // NOTE: itemId is required for track items
            picture={work.image}
            title={work.title}
            size={size}
            key={work.id}
            canonical={( work.authors ? formatUrl(work.id, work.authors, work.title, true) : "")}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

//Composant Work
function Card(props) {
  const { itemId, picture, title, size, canonical } = props;
  return (
    <div tabIndex={0} className="noselect">
      <div className={style[size].listBookContainer}>
        <a
          href={"/share/book/" + itemId + "/" + canonical}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {picture ? (
            <img
              key={itemId}
              src={cst.WORK_PICTURE + picture + cst.FIN_LIEN}
              alt={title}
              className={style[size].bookCover}
            />
          ) : (
            <DefaultCover title={title} style={style[size].bookCover} />
          )}
        </a>
      </div>
    </div>
  );
}

export default WorkHorizontalList;
