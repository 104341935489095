import TagComment from "./TagComment";
import DefaultPeople from "./DefaultPeople";
import { pluralize } from "../utils/functions";
import likeAvis from "../images/likeCommAvis.svg";
import commAvis from "../images/commentAvis.svg";
import cst from "../utils/constant";

//composant qui affiche l'avis sur un livre
const Comment = (props) => {
  const { comment, toggleModal } = props;
  const {
    tags,
    id,
    createdOn,
    title,
    content,
    updatedOn,
    commCount,
    likeCount,
  } = comment;
  const creationDate = new Date(createdOn).toLocaleDateString("fr-FR");
  const updatedDate = new Date(updatedOn).toLocaleDateString("fr-FR");
  return (
    <div className="commentScreen-comment">
      <div
        onClick={() => toggleModal()}
        className="commentScreen-comment-header"
      >
        {comment.author.picture ? (
          <img
            src={cst.PEOPLE_PICTURE + comment.author.picture + cst.FIN_LIEN}
            alt="Profil picture"
            className="comment-header-picture"
          />
        ) : (
          <DefaultPeople style="comment-header-picture" />
        )}
        <div className="comment-header-userinfo">
          <div className="comment-header-div">
            <p className="comment-header-usertext">
              <span>
                {comment.author.nickname ? comment.author.nickname : "Gleepher"}
              </span>
              <span className="comment-header-creationDate">
                {" "}
                - {creationDate}
              </span>
            </p>
            <svg
              className="comment-option"
              viewBox="0 0 18 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.88129 0.924561C1.79038 0.924561 0.917656 1.79729 0.917656 2.8882C0.917656 3.97911 1.79038 4.85183 2.88129 4.85183C3.9722 4.85183 4.84493 3.97911 4.84493 2.8882C4.89947 1.79729 3.9722 0.924561 2.88129 0.924561Z"
                fill="#B2B2B2"
              />
              <path
                d="M9.09942 0.924561C8.00851 0.924561 7.13579 1.79729 7.13579 2.8882C7.13579 3.97911 8.00851 4.85183 9.09942 4.85183C10.1903 4.85183 11.0631 3.97911 11.0631 2.8882C11.0631 1.79729 10.1903 0.924561 9.09942 0.924561Z"
                fill="#B2B2B2"
              />
              <path
                d="M15.3177 0.924561C14.2268 0.924561 13.354 1.79729 13.354 2.8882C13.354 3.97911 14.2268 4.85183 15.3177 4.85183C16.4086 4.85183 17.2813 3.97911 17.2813 2.8882C17.2813 1.79729 16.4086 0.924561 15.3177 0.924561Z"
                fill="#B2B2B2"
              />
            </svg>
          </div>
          <TagComment className="comment-header-usertags" tags={tags} id={id} />
        </div>
      </div>
      <p className="commentScreen-title">{title}</p>
      <p className="commentScreen-content">{content}</p>
      {creationDate !== updatedDate && (
        <p className="comment-header-updateDate">Mis à jour le {updatedDate}</p>
      )}
      <hr className="comment-line" />
      <div
        onClick={() => toggleModal()}
        className="commentScreen-comment-footer"
      >
        <p className="comment-footer-stats">
          <span className="semibold">{likeCount}</span>{" "}
          {pluralize(likeCount, "aime", "aiment")}∙
          <span className="semibold">{commCount}</span>{" "}
          {pluralize(commCount, "commentaire", "commentaires")}∙
        </p>
        <div>
          <img
            className="comment-footer-interaction"
            src={likeAvis}
            alt="like"
          />
          <img
            className="comment-footer-interaction"
            src={commAvis}
            alt="commentaires"
          />
        </div>
      </div>
    </div>
  );
};

export default Comment;
