import cst from "../utils/constant";
import DefaultCover from "./DefaultCover";

//Component affichant les livres d'une étagère
const BookList = (props) => {
  const { books, loading } = props;
  let listBook = [];
  if (loading) {
    listBook = books.map((book) => (
      <span className="shelf-book-container" key={book.id}>
        <div className="workPicture shelfscreen-booklist-loadingcover" />
      </span>
    ));
  } else {
    listBook = books
      .filter((book) => book.id !== "")
      .map((book) => (
        <span className="shelf-book-container" key={book.id}>
          <a href={"/share/book/" + book.id} style={{ textDecoration: "none" }}>
            {book.image ? (
              <img
                src={cst.WORK_PICTURE + book.image + cst.FIN_LIEN}
                alt="Book Cover"
                className="workPicture"
              />
            ) : (
              <DefaultCover title={book.title} style={"workPicture-default"} />
            )}
          </a>
        </span>
      ));
  }

  return <div className="containerListBook">{listBook}</div>;
};

export default BookList;
