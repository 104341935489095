import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import commAvis from "../images/commentAvis.svg";
import likeAvis from "../images/likeCommAvis.svg";
import cst from "../utils/constant";
import useDrag from "../utils/useDrag";
import DefaultPeople from "./DefaultPeople";
import { onWheel } from "../utils/functions";
import TagComment from "./TagComment";

const now = new Date();

//Composant pour render les commentaires sous forme de liste
function CommentHorizontalList(props) {
  let items = props.comment;

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragStop} style={{ cursor: "pointer" }}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        <div className="bookscreen-similarbook-allcard-container" />
        {items.map((ppl) => (
          <a
            key={ppl.id} // NOTE: itemId is required for track items
            href={"/share/review/" + ppl.id}
            style={{ textDecoration: "none" }}
          >
            <Card
              author={ppl.author.nickname}
              picture={ppl.author.picture}
              creation={ppl.updatedOn}
              tags={ppl.tags}
              title={ppl.title}
              content={ppl.content}
              likeCount={ppl.likeCount}
              commCount={ppl.commCount}
              key={ppl.id}
            />
          </a>
        ))}
      </ScrollMenu>
    </div>
  );
}

//Composant de chaque commentaire
function Card(props) {
  let {
    itemId,
    author,
    creation,
    tags,
    title,
    content,
    likeCount,
    commCount,
    picture,
  } = props;
  creation = formatDate(creation);
  return (
    <div>
      <div className="bookscreen-comment-listcomment-container noselect">
        <div className="bookscreen-comment-card-container">
          <div className="bookscreen-comment-header-container">
            {picture ? (
              <img
                src={cst.PEOPLE_PICTURE + picture + cst.FIN_LIEN}
                alt="People Picture"
                className="bookscreen-comment-picture-style"
              />
            ) : (
              <DefaultPeople style="bookscreen-comment-picture-style" />
            )}

            <div className="bookscreen-comment-info-container">
              <div className="bookscreen-comment-author-container">
                <span className="bookscreen-comment-author-police">
                  {author ? author : "Gleepher"}
                </span>

                <span className="bookscreen-comment-date-police">
                  - {creation}
                </span>
              </div>
              <TagComment tags={tags} id={itemId} />
            </div>
          </div>
          <div className="bookscreen-comment-content-container">
            <div className="bookscreen-comment-content-title-police">
              {title}
            </div>
            <div className="bookscreen-comment-content-police">{content}</div>
          </div>
          <hr size="1" color="#E3E3E3" />
          <div className="bookscreen-comment-footer-container bookscreen-comment-footer-police ">
            <img
              src={likeAvis}
              alt="like"
              className="bookscreen-comment-footer-like"
            />
            <span className="bookscreen-comment-footer-space">{likeCount}</span>
            <img
              src={commAvis}
              alt="comm"
              className="bookscreen-comment-footer-comment"
            />
            <span className="bookscreen-comment-footer-space">{commCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

//Format TS in 'X jours' ou 'X heures'
export function formatDate(date) {
  date = new Date(date);
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours > 24) {
    return diffDays == 1 ? diffDays + " jour" : diffDays + " jours";
  } else {
    return diffHours + " heures";
  }
}

export default CommentHorizontalList;
