import CommentHorizontalList from "./CommentHorizontalList";
import commentIcon from "../images/commentIcon.svg";
import { pluralize } from "../utils/functions";

//Component affichant les commentaires d'un livre
const CommentOnWork = (props) => {
  let { commentInfo, commentCount } = props;
  return (
    <div>
      <div className="bookscreen-comment-title-container">
        <div className="bookscreen-comment-icon-container">
          <img
            src={commentIcon}
            alt="icon"
            className="bookscreen-comment-icon-style"
          />
        </div>
        <h2 className="resumeTitlePolice">
          {pluralize(
            commentCount,
            `${commentCount} avis sur ce livre`,
            "",
            "Pas encore d'avis sur ce livre"
          )}
        </h2>
      </div>
      <CommentHorizontalList comment={commentInfo} />
    </div>
  );
};

export default CommentOnWork;
