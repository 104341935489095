import React from "react";
import TopBar from "../components/TopBar";

const NotFoundScreen = (props) => {
  const { toggleModal } = props;
  return (
    <>
      <TopBar toggleModal={toggleModal} />
      <div className="notfoud-container">
        <span className="notfound-title-police">404</span>
        <span className="notfound-text-police">Page Not Found</span>
      </div>
    </>
  );
};

export default NotFoundScreen;
