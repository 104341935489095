//Default composant pour render une couverture en fond jaune avec le titre
const DefaultCover = (props) => {
  const { title, style } = props;
  return (
    <div
      style={{
        background: "#fdf9e3",
      }}
      className={`bookcover-default-container  ${style}`}
    >
      <div className="bookcover-default-border">
        <span className="bookcover-default-title-police">{title}</span>
      </div>
    </div>
  );
};

export default DefaultCover;
