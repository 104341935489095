import WorkHorizontalList from "./WorkHorizontalList";

//Component affichant les livres du même auteur
const WorksSameAuthor = (props) => {
  let { works } = props;
  return (
    <div className="bookscreen-similarbook-container">
      <div className="bookscreen-sameauthor-title-container">
        <h2 className="resumeTitlePolice">Livres du même auteur</h2>
      </div>
      <WorkHorizontalList works={works} size={"default"} />
    </div>
  );
};

export default WorksSameAuthor;
