import DefaultPeople from "./DefaultPeople";
import { formatDate } from "./CommentHorizontalList";
import cst from "../utils/constant";

const Reaction = (props) => {
  const { reaction, toggleModal } = props;

  return (
    <div className="commentScreen-reaction">
      <div className="reaction-userinfo">
        <div onClick={() => toggleModal()} style={{ cursor: "pointer" }}>
          {reaction.author.picture ? (
            <img
              src={cst.PEOPLE_PICTURE + reaction.author.picture + cst.FIN_LIEN}
              alt="Profil picture"
              className="roundPictureProfil"
            />
          ) : (
            <DefaultPeople style="small-people-picture" />
          )}
        </div>
        <div className="reaction-usertext">
          <div onClick={() => toggleModal()} style={{ cursor: "pointer" }}>
            <p className="reaction-username semibold">
              {reaction.author.nickname ? reaction.author.nickname : "Gleepher"}
            </p>
          </div>
          <p className="reaction-text">{reaction.text}</p>
          <p className="reaction-date">{formatDate(reaction.updatedOn)}</p>
        </div>
      </div>
      <div onClick={() => toggleModal()} style={{ cursor: "pointer" }}>
        <svg
          className="comment-option"
          viewBox="0 0 18 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.88129 0.924561C1.79038 0.924561 0.917656 1.79729 0.917656 2.8882C0.917656 3.97911 1.79038 4.85183 2.88129 4.85183C3.9722 4.85183 4.84493 3.97911 4.84493 2.8882C4.89947 1.79729 3.9722 0.924561 2.88129 0.924561Z"
            fill="#B2B2B2"
          />
          <path
            d="M9.09942 0.924561C8.00851 0.924561 7.13579 1.79729 7.13579 2.8882C7.13579 3.97911 8.00851 4.85183 9.09942 4.85183C10.1903 4.85183 11.0631 3.97911 11.0631 2.8882C11.0631 1.79729 10.1903 0.924561 9.09942 0.924561Z"
            fill="#B2B2B2"
          />
          <path
            d="M15.3177 0.924561C14.2268 0.924561 13.354 1.79729 13.354 2.8882C13.354 3.97911 14.2268 4.85183 15.3177 4.85183C16.4086 4.85183 17.2813 3.97911 17.2813 2.8882C17.2813 1.79729 16.4086 0.924561 15.3177 0.924561Z"
            fill="#B2B2B2"
          />
        </svg>
      </div>
    </div>
  );
};

export default Reaction;
