import { Redirect, useLocation } from "react-router-dom";
import NotFoundScreen from "./NotFoundScreen";

const RedirectFromQueryParam = ({ path }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = path.match(/:(\w+)/)[1];
  const value = searchParams.get(param);

  if (!value) {
    return <NotFoundScreen />;
  }

  return <Redirect to={{ pathname: path.replace(":" + param, value) }} />;
};

export default RedirectFromQueryParam;
