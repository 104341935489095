export default {
  shelf: {
    id: "",
    owner: {
      id: "",
      picture: "",
      nickname: "gleepher",
      more: {
        follower: 0,
        followee: 0,
      },
    },
    image: "",
    followCount: 0,
    color: "#FFFFF",
    booksCover: [
      {
        id: "1",
        title: "",
        image: "",
      },
      {
        id: "2",
        title: "",
        image: "",
      },
      {
        id: "3",
        title: "",
        image: "",
      },
      {
        id: "4",
        title: "",
        image: "",
      },
      {
        id: "5",
        title: "",
        image: "",
      },
      {
        id: "6",
        title: "",
        image: "",
      },
      {
        id: "7",
        title: "",
        image: "",
      },
      {
        id: "8",
        title: "",
        image: "",
      },
      {
        id: "9",
        title: "",
        image: "",
      },
      {
        id: "10",
        title: "",
        image: "",
      },
      {
        id: "11",
        title: "",
        image: "",
      },
      {
        id: "12",
        title: "",
        image: "",
      },
    ],
    icon: "",
    description: "",
    type: "",
    booksCount: 100,
    name: "",
    commCount: 0,
    likeCount: 0,
  },
  book: {
    additionalData: {
      stats: {
        comments: 0,
        follow: 0,
        like: 0,
        own: 0,
        read: 0,
        reading: 0,
        wish: 0,
      },
    },
    data: {
      authors: [""],
      clilObj: {
        color: "#FFFFFF",
        id: "",
        libelle: "",
        topId: "",
      },
      description: "",
      edition: "",
      glid: "",
      id: "",
      image: "",
      pages: "",
      publicationYear: "",
      publisher: "",
      title: "",
      provider: "",
    },
    bookOwners: {
      owners: [
        {
          id: "",
          nickname: "anonyme1",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme2",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme3",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme4",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme5",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme6",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme7",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme8",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme9",
          picture: "",
        },
        {
          id: "",
          nickname: "anonyme10",
          picture: "",
        },
      ],
      ownersTotal: 10,
    },
    comments: [
      {
        data: {
          tags: {
            wish: false,
            like: false,
            own: false,
            reading: false,
            read: false,
          },
          updatedOn: 0,
          content: "",
          title: "",
          likeCount: 0,
          commCount: 0,
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
        },
      },
    ],
    bySameAuthor: [
      {
        title: "",
        id: "",
        image: "",
      },
    ],
    similarBooks: [
      {
        title: "",
        id: "",
        image: "",
      },
    ],
    shelvesWithThisBook: [
      {
        id: "",
        owner: {
          id: "",
          picture: "",
          nickname: "",
        },
        image: "",
        color: "",
        booksCover: [
          {
            id: "",
            title: "",
            image: "",
          },
          {
            id: "",
            title: "",
            image: "",
          },
        ],
        collaborative: false,
        privacy: "",
        name: "",
        booksCount: 0,
      },
    ],
  },
  comment: {
    book: {
      id: "9782070584628",
      image: "393342847eba03ddb14561d7c616fabee5b511e0d897528b5bdb5d672fe3f9bb",
      title: "Harry Potter à l'école des sorciers",
      authors: ["J. K. Rowling"],
      publicationYear: "2017",
    },
    comment: {
      tags: {
        wish: false,
        like: true,
        own: true,
        reading: false,
        read: true,
      },
      createdOn: 1595623103619,
      updatedOn: 1595623103775,
      content:
        "J'ai lu le premier tome l'année dernière, et j'ai eu un combat assez animé avec un de mes professeur. Lui n'aimant pas le style d'Harry et moi qui y adhère tout bonnement. On entre dans une histoire à travers les yeux d'un enfant de 10 ans. Il découvre la magie et ce qu'il est réellement ! C'est un monde merveilleux qui s'ouvre à lui comme à nous. À lire encore et encore. ",
      title: "Magique !! ",
      likeCount: 11,
      commCount: 11,
      author: {
        id: "",
        nickname: "",
        picture: "",
      },
      clilObj: {
        color: "#FFFFFF",
        id: "",
        libelle: "",
      },
      id: "9782070584628-2019E9A409C84D0EB36A96C47F367C07",
      comments: [
        {
          id: "BBB5A12C10564724802A6FBE7318C5C4",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "11",
          createdOn: 1639155425715,
          updatedOn: 1639155425715,
        },
        {
          id: "8173A150B2114C229865F270BDBCD9EE",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "10",
          createdOn: 1639155421131,
          updatedOn: 1639155421131,
        },
        {
          id: "BA97130C9AC642C3A5723705FD3D9D52",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "9",
          createdOn: 1639155416690,
          updatedOn: 1639155416690,
        },
        {
          id: "F126BFD286B54301BED8C1BBD7C5FE31",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "8",
          createdOn: 1639155412712,
          updatedOn: 1639155412712,
        },
        {
          id: "B940258BDA104F0DAAB539B74A52525E",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "7",
          createdOn: 1639155408586,
          updatedOn: 1639155408586,
        },
        {
          id: "293182BC219F46DF99C4EF463C6711E6",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "6",
          createdOn: 1639155404903,
          updatedOn: 1639155404903,
        },
        {
          id: "54F0EF15C5704B379444EFB9EE7DBE43",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "5",
          createdOn: 1639155401867,
          updatedOn: 1639155401867,
        },
        {
          id: "E87AFB0513DE4EF8864DCA7BC0DF1660",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "Oui",
          createdOn: 1639155393081,
          updatedOn: 1639155393081,
        },
        {
          id: "6019EC003E964F448E85604D6F0CCDD0",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "C’est certain ! 😀",
          createdOn: 1639155380337,
          updatedOn: 1639155380337,
        },
        {
          id: "3CB5E98E1B4C4784A526678189A146AA",
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
          text: "😀😀😀",
          createdOn: 1597751971193,
          updatedOn: 1597751971193,
        },
      ],
    },
  },
};
