import helpers from "../utils/APIHelper";
import { Helmet } from 'react-helmet-async';
import model from "../models/model";
import React from "react";
import BackGroundShelf from "../components/BackGroundShelf";
import BookList from "../components/BookList";
import OwnerShelfPanel from "../components/OwnerShelfPanel";
import BottomBarUser from "../components/BottomBarUser";
import Lottie from "react-lottie";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";
import TopBar from "../components/TopBar";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

//Composant renderant tous les composants de la page étagère publique
export default class ShelfScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shelf: model.shelf,
      loading: true,
    };
  }

  async componentDidMount() {
    const pathName = window.location.pathname.split("/");
    const id = pathName[3];
    const res = await helpers.fetchShelf(id);
    if (res.message === "NotExist" || res.error) {
      alert("Cette étagère n'existe pas");
    } else {
      this.setState({ shelf: res, loading: false });
    }
  }

  render = () => {
    const { shelf, loading } = this.state;
    const { toggleModal } = this.props;
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <TopBar toggleModal={toggleModal} />
        <div className="backgroundGrey">
          <div className="fixWidth">
            <div onClick={toggleModal} style={{ cursor: "pointer" }}>
              <BackGroundShelf
                image={shelf.image}
                color={shelf.color}
                name={shelf.name}
                nb_book={shelf.booksCount}
                loading={loading}
                type={shelf.type}
              />
              <OwnerShelfPanel shelf={shelf} loading={loading} />
            </div>
            <div
              className="backgroundContent"
              style={
                shelf.booksCover.length > 18
                  ? { height: "auto" }
                  : { height: "100vh" }
              }
            >
              <div className="backgroundOpacityListBookInShelf" />
              {loading && (
                <div className="shelfscreen-loading-container">
                  <Lottie options={defaultOptions} width={50} />
                </div>
              )}
              <BookList books={shelf.booksCover} loading={loading} />
            </div>
          </div>
          <BottomBarUser
            user={shelf.owner}
            showingOnChange={toggleModal}
            isComment={false}
          />
        </div>
      </>
    );
  };
}
