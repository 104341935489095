import author from "../images/author.svg";

//Component pour render la partie author
const AuthorPanel = (props) => {
  const { name } = props;
  return (
    <div>
      <div className="containerAuthorPanel">
        <div className="bookScreen-authorLog-container">
          <img
            src={author}
            alt="author"
            className="bookScreen-authorLog-Police"
          />
        </div>
        <span className="authorNamePolice">{name} </span>
      </div>
    </div>
  );
};

export default AuthorPanel;
