//Composant qui render les tags dans l'avis sous le pseudo de l'auteur
import own from "../images/ownAvis.svg";
import wish from "../images/wishAvis.svg";
import reading from "../images/readingAvis.svg";
import read from "../images/readAvis.svg";
import like from "../images/likeAvis.svg";

const colorTags = {
  own: "#7AB9FF",
  wish: "#FFAE4A",
  reading: "#D59AED",
  read: "#89DA8E",
  like: "#F97E60",
};

const typeTags = {
  own: own,
  wish: wish,
  reading: reading,
  read: read,
  like: like,
};

const TagComment = (props) => {
  const { tags, id } = props;

  const tagsOrder = {
    own: tags["own"],
    wish: tags["wish"],
    reading: tags["reading"],
    read: tags["read"],
    like: tags["like"],
  };

  let tagsToShow = Object.entries(tagsOrder)
    .filter(([key, value]) => value == true)
    .map(([key, value]) => (
      <div
        className="bookpeople-comment-tag-container"
        style={{ background: colorTags[key] }}
        key={id + key}
      >
        <img
          src={typeTags[key]}
          alt="tag"
          className="bookpeople-comment-tags-style"
        />
      </div>
    ));
  return <div className="bookpeople-comment-tags-container">{tagsToShow}</div>;
};

export default TagComment;
