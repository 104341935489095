import React, { useState } from "react";
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ShelfScreen from "./screens/ShelfScreen";
import BookScreen from "./screens/BookScreen";
import CommentScreen from "./screens/CommentScreen";
import ModalDesktop from "./components/ModalDesktop";
import RedirectFromQueryParam from "./screens/RedirectFromQueryParam";
import NotFoundScreen from "./screens/NotFoundScreen";
import HomeScreen from "./screens/HomeScreen";

export default function App() {
  const [isShowing, changeVisibility] = useState(false);
  const [minimizeModal, mailSent] = useState(false);

  const showingOnChange = () => {
    changeVisibility(!isShowing);
  };

  const minimizeModalOnChange = () => {
    mailSent(true);
  };

  return (
    <HelmetProvider>

      <Router>
        <ModalDesktop
          isShowing={isShowing}
          hide={() => showingOnChange()}
          alreadySent={minimizeModal}
          minimizeModal={() => minimizeModalOnChange()}
        />
        <div style={{ marginTop: 50 }} />
        <Switch>
          <Route
              exact
              path="/"
              component={() => {
                window.location.replace("https://suggestion.gleeph.net");
                return null;
              }}
          />
          <Route path="/share/book/isbn">
            <RedirectFromQueryParam path="/share/book/:id" />
          </Route>
          <Route path="/share/book/:id">
            <BookScreen toggleModal={() => showingOnChange()} />
          </Route>

          <Route path="/share/review/:id">
            <CommentScreen toggleModal={() => showingOnChange()} />
          </Route>
          <Route path="/share/review">
            <RedirectFromQueryParam path="/share/review/:id" />
          </Route>
          <Redirect from="/share/comment/:id" to="/share/review/:id" />

          <Route path="/share/shelf/:id">
            <ShelfScreen toggleModal={() => showingOnChange()} />
          </Route>
          <Route path="/share/shelf">
            <RedirectFromQueryParam path="/share/shelf/:id" />
          </Route>
          <Route>
            <NotFoundScreen toggleModal={() => showingOnChange()} />
          </Route>
        </Switch>
      </Router>

    </HelmetProvider>
  );
}
