import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import React, { useState } from "react";
import close from "../images/FermerModal.svg";
import logo from "../images/logo.svg";
import toggle from "../images/toggleRgpd.svg";
import send from "../images/send.svg";
import playStore from "../images/playStore.png";
import appleStore from "../images/appleStore.png";
import Leanplum from "leanplum-sdk";

const env = process.env.REACT_APP_BUILD;

//Modal pour le site de partage d'étagère et de fiche livre
const ModalDesktop = ({ isShowing, hide, minimizeModal, alreadySent }) => {
  const [text, setText] = useState("");
  const [mailSend, setMailSend] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [rgpd, setRgpd] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)`,
  });

  function onClick() {
    hide();
    mailSend ? minimizeModal() : null;
  }

  //Check format email
  function handleOnChange(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setText(email);
    if (re.test(email)) {
      setIsMail(true);
    } else {
      setIsMail(false);
    }
  }

  return (
    <>
      {isShowing && (
        <div className="modal-overlay">
          <div className="modal-wrapper">
            <div>
              <div className="modal">
                <div onClick={onClick} style={{ cursor: "pointer" }}>
                  <img src={close} alt="close" className="closeBottomBar" />
                </div>
                <div className="modal-container">
                  <div className="defaultProfile">
                    <img src={logo} alt="Profil picture" />
                  </div>
                  <div className="modal-header-container">
                    <span className="modal-header-police">
                      Voir plus de contenu
                    </span>
                  </div>
                  <div className="modal-body-container">
                    <span className="modal-body-police">
                      {isMobile
                        ? "Connectez-vous à Gleeph et faites le plein d’idées de lecture."
                        : "Installez Gleeph sur votre smartphone et faites le plein d’idées de lecture."}
                    </span>
                  </div>
                  <div className="modal-container-store">
                    <img
                      src={playStore}
                      alt="playStore"
                      className="playStore"
                    />
                    <img
                      src={appleStore}
                      alt="appleStore"
                      className="appleStore"
                    />
                  </div>
                </div>
                {!alreadySent && (
                  <div className="modal-container-form">
                    <div className="modal-container-textfield">
                      <input
                        className="modal-textfield-police"
                        value={text}
                        onChange={(e) => handleOnChange(e.target.value)}
                        type="email"
                        placeholder="Votre email"
                        style={
                          text.length > 0
                            ? {
                                color: "#252525",
                                borderBottom: "1px solid #929292",
                              }
                            : {
                                color: "#B2B2B2",
                                borderBottom: "1px solid #C4C4C4",
                              }
                        }
                      />
                    </div>
                    <Submit
                      mailSend={mailSend}
                      setMailSend={setMailSend}
                      text={text}
                      isMobile={isMobile}
                      isMail={isMail}
                      rgpd={rgpd}
                      setRgpd={setRgpd}
                    />
                  </div>
                )}
              </div>
              {!isMobile && (
                <div className="modal-rpgd-content-police modal-rgpd-content-container">
                  <div>
                    La société F-451 collecte et traite vos données à caractère
                    personnel afin de vous adresser le lien de l’application,
                    vous adresser des communications marketing et réaliser des
                    analyses marketing et statistiques.
                  </div>
                  <br />
                  <div>
                    Vous pouvez demander l’accès, la rectification, l’effacement
                    et la portabilité de ses données. Vous pouvez vous opposer
                    aux traitements ou demander la limitation des traitements à
                    l’adresse :{" "}
                    <a
                      style={{ color: "inherit" }}
                      href="mailto:contact@gleeph.net"
                    >
                      contact@gleeph.net
                    </a>{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

//Composant pour gérer le render du bouton d'envoi de la modal
function Submit(props) {
  const { mailSend, setMailSend, isMobile, text, isMail, rgpd, setRgpd } =
    props;
  if (mailSend) {
    return (
      <div>
        <div
          onClick={() => setMailSend(true)}
          className="modal-submit-button-validate"
        >
          <div className="modal-container-submit">
            <img src={send} alt="Profil picture" />
            <span className="modal-submit-police" style={{ paddingLeft: 11 }}>
              Email envoyé !
            </span>
          </div>
        </div>
        <div className="modal-postsend-container">
          {/*<span className="modal-postsend-police-1">
            Vous ne l’avez pas reçu ?{" "}
          </span>
          <span
            className="modal-postsend-police-2"
            onClick={() => reSendMail(text)}
          >
            Renvoyer
          </span>*/}
        </div>
      </div>
    );
  } else {
    if (isMobile) {
      return (
        <a href={cst.DEEPLINK} style={{ textDecoration: "none" }}>
          <div
            style={{ background: "#FFAE4A" }}
            className="modal-submit-button"
          >
            <span className="modal-submit-police"> ouvrir l’application</span>
          </div>
        </a>
      );
    } else {
      if (isMail && rgpd) {
        return (
          <div>
            <div className="modal-container-rgpd">
              {!rgpd ? (
                <div
                  className="modal-toggle-rgpd"
                  onClick={() => {
                    setRgpd(!rgpd);
                    startLeanplum();
                  }}
                />
              ) : (
                <img
                  src={toggle}
                  alt="toggle"
                  onClick={() => setRgpd(!rgpd)}
                  style={{ cursor: "pointer" }}
                />
              )}

              <div className="modal-container-text-rgpd">
                <span className="modal-police-rgpd">
                  J'accepte de recevoir des informations de Gleeph par email
                </span>
              </div>
            </div>
            <div
              onClick={() => {
                setMailSend(true);
                sendMail(text);
              }}
              style={{ background: "#FFAE4A", marginBottom: 30 }}
              className="modal-submit-button"
            >
              <span className="modal-submit-police">
                M’ENVOYER UN LIEN PAR EMAIL
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="modal-container-rgpd">
              {!rgpd ? (
                <div
                  className="modal-toggle-rgpd"
                  onClick={() => {
                    setRgpd(!rgpd);
                    startLeanplum();
                  }}
                />
              ) : (
                <img
                  src={toggle}
                  alt="toggle"
                  onClick={() => setRgpd(!rgpd)}
                  style={{ cursor: "pointer" }}
                />
              )}

              <div className="modal-container-text-rgpd">
                <span className="modal-police-rgpd">
                  J'accepte de recevoir des informations de Gleeph par email
                </span>
              </div>
            </div>
            <div
              className="modal-submit-button-disable"
              style={{
                marginBottom: 30,
                cursor: "not-allowed",
                color: "#595959",
              }}
            >
              <span className="modal-submit-police-disable">
                M’ENVOYER UN LIEN PAR EMAIL
              </span>
            </div>
          </div>
        );
      }
    }
  }
}

function startLeanplum() {
  // This value should be set to true only if you're developing on your server.
  var isDevelopmentMode = false;

  // Insert your API keys here.
  if (isDevelopmentMode) {
    Leanplum.setAppIdForDevelopmentMode(cst.APP_ID, cst.DEVELOPMENT_KEY);
  } else {
    Leanplum.setAppIdForProductionMode(cst.APP_ID, cst.PRODUCTION_KEY);
  }
  const LP_ID = Leanplum.getUserId();
  Leanplum.setUserAttributes(LP_ID, { prospect: false });
}

//Function pour envoyer le mail de bienvenue à l'email entré
async function sendMail(email) {
  const LP_ID = Leanplum.getUserId();
  await fetch(cst.SUBSCRIPTION_API[env], {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify({ email, user: LP_ID }), // body data type must match "Content-Type" header
  });
}

async function reSendMail(text) {
  await sendMail(text);
}

export default ModalDesktop;
