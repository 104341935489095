import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import ShelfHorizontalList from "./ShelfHorizontalList";
import iconShelf from "../images/iconShelf.svg";
import iconShelfMin from "../images/iconShelfMin.svg";
import { pluralize } from "../utils/functions";

const icon = {
  mini: {
    shelf: iconShelfMin,
  },
  normal: {
    shelf: iconShelf,
  },
};

//Component affichant les étagères en relation
const ShelvesRelation = (props) => {
  let { shelves } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  return (
    <div>
      <div className="bookscreen-comment-title-container">
        <div className="bookscreen-shelf-icon-container">
          <img src={icon[screen].shelf} alt="shelves" />
        </div>
        <h2 className="resumeTitlePolice">
          {pluralize(
            shelves.length,
            "Ce livre est dans 1 étagère",
            `Ce livre est dans ${shelves.length} étagères`,
            "Ce livre n'est dans aucune étagère"
          )}
        </h2>
      </div>
      <ShelfHorizontalList shelves={shelves} />
    </div>
  );
};

export default ShelvesRelation;
