import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import iconBook from "../images/iconBook.svg";
import iconBookMin from "../images/iconBookMin.svg";
import iconPublic from "../images/iconPublic.svg";
import iconPublicMin from "../images/iconPublicMin.svg";
import iconCollaborative from "../images/iconCollaborative.svg";
import iconCollaborativeMin from "../images/iconCollaborativeMin.svg";
import iconShare from "../images/iconShareWhite.svg";
import iconShareMin from "../images/iconShareWhiteMin.svg";

const icon = {
  mini: {
    book: iconBookMin,
    public: iconPublicMin,
    collaborative: iconCollaborativeMin,
    share: iconShareMin,
  },
  normal: {
    book: iconBook,
    public: iconPublic,
    collaborative: iconCollaborative,
    share: iconShare,
  },
};

const ImgComponent = ({ image, color, type, children }) => {
  if (!image) {
    return (
      <div
        style={{
          background:
            "linear-gradient(162.44deg, rgba(37, 37, 37, 0.32) 0%, rgba(37, 37, 37, 0) 100%)," +
            color,
        }}
        className="backgroundShelf"
      >
        {children}
      </div>
    );
  }
  if (type === "CHRISTMAS") {
    return (
      <div
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(37, 37, 37, 0.5) 24.22%, rgba(37, 37, 37, 0.2) 57.66%), " +
            `url(${
              require("../images/shelfBG/ETAGERE_NOEL_" +
                cst.SHELF_COLOR[color] +
                ".png").default
            })`,
        }}
        className="backgroundShelf"
      >
        {children}
      </div>
    );
  }

  let BGShelfURL = cst.SHELF_PICTURE + image;
  let hiResUrl;
  if (cst.ISRETINA) {
    hiResUrl = BGShelfURL + "-1800";
  } else {
    hiResUrl = BGShelfURL + "-900";
  }
  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgba(37, 37, 37, 0.5) 24.22%, rgba(37, 37, 37, 0.2) 57.66%), " +
          `url(${hiResUrl}), url(${BGShelfURL})`,
      }}
      className="backgroundShelf"
    >
      {children}
    </div>
  );
};

//Composant du Header de ShelfScreen avec le background
const BackGroundShelfFull = (props) => {
  const { image, color, name, nb_book, screen, type } = props;

  return (
    <div>
      <ImgComponent image={image} color={color} type={type}>
        <div className="containerTopShelf">
          <div className="titleShelf">
            <span>{name} </span>
          </div>
          <div className="containerInfoShelf policeInfoShelf">
            <div className="infoShelf">
              <img src={icon[screen]["book"]} alt="logo" />
              <div className="topshelf-info-nbbook">
                <span className="topshelf-icon-nbbook">{nb_book}</span>
                <span>•</span>
              </div>
              <img src={icon[screen]["public"]} alt="logo" />
              <div className="topshelf-info-public">
                <span className="topshelf-icon-public">PUBLIQUE</span>
                {/* <span>•</span> */}
              </div>
              {/* <img src={icon[screen]["collaborative"]} alt="logo" />
              <div className="topshelf-info-collab">
                <span>COLLABORATIVE</span>
              </div> */}
            </div>
            <div className="followButton">
              <img src={icon[screen]["share"]} alt="share" />
              <span style={{ paddingLeft: 5 }}>s'ABONNER</span>
            </div>
          </div>
        </div>
      </ImgComponent>
    </div>
  );
};

//Composant d'attente de remplissage du header de ShelfScreen
const LoadingBackGroundShelf = (props) => {
  const { screen } = props;
  return (
    <div>
      <div style={{ backgroundColor: "#b2b2b2" }} className="backgroundShelf">
        <div className="containerTopShelf-loading">
          <div className="titleShelf-loading-up" />
          <div className="titleShelf-loading-down" />
          <div className="containerInfoShelf policeInfoShelf">
            <div className="infoShelf">
              <img src={icon[screen]["book"]} alt="logo" />
              <div className="topshelf-info-nbbook">
                <span className="topshelf-icon-nbbook backgroundshelf-police-loading">
                  1000
                </span>
                <span>•</span>
              </div>
              <img src={icon[screen]["public"]} alt="logo" />
              <div className="topshelf-info-public">
                <span className="topshelf-icon-public backgroundshelf-police-loading">
                  PUBLIQUE
                </span>
              </div>
            </div>
            <div className="followButton">
              <img src={icon[screen]["share"]} alt="share" />
              <span style={{ paddingLeft: 5 }}>s'ABONNER</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//Component qui affiche le background de l'étagère publique selon le chargement de la page
const BackGroundShelf = (props) => {
  const { image, color, name, nb_book, loading, type } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  if (loading) {
    return <LoadingBackGroundShelf screen={screen} />;
  } else {
    return (
      <BackGroundShelfFull
        screen={screen}
        image={image}
        color={color}
        name={name}
        nb_book={nb_book}
        type={type}
      />
    );
  }
};

export default BackGroundShelf;
