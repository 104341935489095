import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import cst from "../utils/constant";
import close from "../images/Fermer.svg";
import DefaultPeople from "../components/DefaultPeople";
import { DownloadApp } from "../utils/functions";

//Component qui affiche la Bottom bar pour la page étagère publique et avis
const BottomBarUser = (props) => {
  const { picture, nickname } = props.user;
  const { isComment } = props;

  const { showingOnChange } = props;
  const [show, setShow] = useState(true);
  const isMobile = useMediaQuery({
    query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)`,
  });

  return (
    <div
      className="bottomBar"
      style={
        show
          ? { position: "fixed" }
          : { position: "relative", background: "#252525" }
      }
    >
      {show && (
        <img
          onClick={() => setShow(false)}
          src={close}
          alt="close"
          className="closeBottomBar"
        />
      )}
      <div className="containerBottomBar">
        <div onClick={() => showingOnChange()} style={{ cursor: "pointer" }}>
          {picture ? (
            <img
              src={cst.PEOPLE_PICTURE + picture + cst.FIN_LIEN}
              alt="Profil picture"
              className="roundPictureProfilBottomBar"
            />
          ) : (
            <DefaultPeople style="roundPictureProfilBottomBar" />
          )}
        </div>

        <div className="containerTextBottomBar">
          <span className="titleBottomBarPolice">
            {isComment
              ? "Découvrez les avis et la bibliothèque de " + nickname
              : "Découvrez toute la bibliothèque de " + nickname}
          </span>
          <span className="textBottomBarPolice">
            {isMobile
              ? "Connectez-vous à Gleeph et faites le plein d’idées de lecture."
              : "Installez Gleeph sur votre smartphone et faites le plein d’idées de lecture."}
          </span>
        </div>
        <DownloadApp
          isMobile={isMobile}
          setShow={setShow}
          show={show}
          showingOnChange={showingOnChange}
        />
      </div>
    </div>
  );
};

export default BottomBarUser;
