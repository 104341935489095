import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import useDrag from "../utils/useDrag";
import WorkHorizontalList from "./WorkHorizontalList";
import iconBook from "../images/iconBook.svg";
import iconBookMin from "../images/iconBookMin.svg";
import DefaultPeople from "./DefaultPeople";

const icon = {
  mini: {
    book: iconBookMin,
  },
  normal: {
    book: iconBook,
  },
};

//Componsant render la list d'étagère avec le livre de la page
function ShelfHorizontalList(props) {
  let items = props.shelves;

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragStop}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        <div className="bookscreen-similarbook-allcard-container" />
        {items.map((shelf) => (
          <Card
            itemId={shelf.id} // NOTE: itemId is required for track items
            author_nickname={shelf.owner.nickname}
            author_picture={shelf.owner.picture}
            count_book={shelf.booksCount}
            shelf_picture={shelf.image}
            collaborative={shelf.collaborative}
            title={shelf.name}
            works={shelf.booksCover}
            color={shelf.color}
            key={shelf.id}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

//Composant étagère
function Card(props) {
  let {
    itemId,
    author_nickname,
    count_book,
    shelf_picture,
    title,
    works,
    color,
    author_picture,
  } = props;
  let screen = "normal";
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    screen = "mini";
  }
  return (
    <div className="bookscreen-shelf-listshelf-container noselect">
      <div className="bookscreen-shelf-card-container">
        <a href={"/share/shelf/" + itemId} style={{ textDecoration: "none" }}>
          <div
            className="bookscreen-shelf-header-backgroundpicture"
            style={
              shelf_picture === ""
                ? { backgroundColor: color }
                : {
                    backgroundImage: `url(${
                      cst.SHELF_PICTURE + shelf_picture
                    })`,
                  }
            }
          />
          <div className="bookscreen-shelf-header-container">
            <div className="bookscreen-shelf-title-police">{title}</div>
            <div className="bookscreen-shelf-info-container bookscreen-shelf-info-police">
              {author_picture ? (
                <img
                  src={cst.PEOPLE_PICTURE + author_picture + cst.FIN_LIEN}
                  alt="People Picture"
                  className="bookscreen-shelf-picture-style"
                />
              ) : (
                <DefaultPeople style="bookscreen-shelf-picture-style" />
              )}

              <span className="bookscreen-shelf-owner">
                {author_nickname ? author_nickname : "Gleepher"} •
              </span>
              <img src={icon[screen]["book"]} alt="logo" />
              <span className="bookscreen-shelf-countbook-padding">
                {count_book}
              </span>
            </div>
          </div>
        </a>

        <div>
          <WorkHorizontalList works={works} size={"shelf"} />
        </div>
      </div>
    </div>
  );
}

function onWheel(apiObj, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

export default ShelfHorizontalList;
