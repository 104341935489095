import { getMobileOperatingSystem, isRetina } from "./functions";
import arts from "../images/Arts.svg";
import BD from "../images/BD.svg";
import Cartes from "../images/Cartes.svg";
import Documentation from "../images/Documentation.svg";
import Droit from "../images/Droit.svg";
import Encyclopédies from "../images/Encyclopédies.svg";
import Esoterisme from "../images/Esoterisme.svg";
import Geographie from "../images/Geographie.svg";
import Histoire from "../images/Histoire.svg";
import Informatique from "../images/Informatique.svg";
import Jeunesse from "../images/Jeunesse.svg";
import Jeunes_Adultes from "../images/Jeunes_Adultes.svg";
import Litterature_Generale from "../images/Litterature_Generale.svg";
import Livres_pratiques from "../images/Livres_pratiques.svg";
import Management from "../images/Management.svg";
import Medecine from "../images/Medecine.svg";
import Parascolaire from "../images/Parascolaire.svg";
import Religion from "../images/Religion.svg";
import Sans from "../images/Sans.svg";
import Sciences_eco from "../images/Sciences_eco.svg";
import Sciences_hum from "../images/Sciences_hum.svg";
import Sciences_po from "../images/Sciences_po.svg";
import Sciences from "../images/Sciences.svg";
import Scolaire from "../images/Scolaire.svg";
import Techniques from "../images/Techniques.svg";

const env = process.env.REACT_APP_BUILD;
const recette = env == "prod" ? "gleeph" : "gleephpreprod";
const pathName = window.location.pathname.split("/");
const id = pathName[3];
const page = pathName[2];

export default {
  PEOPLE_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/people/",
  WORK_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/",
  SHELF_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/shelves/",
  FIN_LIEN: "-300",
  SCREEN_WIDTH: window.innerWidth,
  SCREEN_HEIGHT: window.innerHeight,
  LIMIT_SCREEN_DESKTOP: 720,
  URL_SHELF: "https://preprod.shelfs.gleeph.com/share/shelf/",
  URL_BOOK: "https://preprod.shelfs.gleeph.com/share/book/",
  DEEPLINK: recette + "://lib/" + page + "/" + id,
  DESCRITION_SHELF: "Découvrez l'étagère de ",
  DESCRITION_BOOK: "Découvrez le livre ",
  OS: getMobileOperatingSystem(),
  APP_ID: "app_2MHapqLfzZNR5kP1O0bgbP5izUJ685i5HEBbnp3m0nE",
  DEVELOPMENT_KEY: "dev_k07hErRP2qnKRR7TA8P1b8UEVXYaf8hXohGTjCDQP0U",
  PRODUCTION_KEY: "prod_hc4tZF4fZO10376zpswOo7x5mjtxJ7QKGgtS3369Fs8",
  DEFAULT_NICKNAME: "John Doe",
  ISRETINA: isRetina(),
  SHELF_COLOR: {
    "#D1C8B3": "LITT_GENERALE",
    "#F2D60F": "JEUNESSE",
    "#FFC947": "SCOLAIRE",
    "#FFAE4A": "PARASCOLAIRE",
    "#FC955C": "DROIT",
    "#F97E60": "BD",
    "#F67E75": "MANAGEMENT",
    "#FC7E87": "SCIENCES_ECO",
    "#FC86B7": "SCIENCES_POL",
    "#F395E0": "SCIENCES_SOCIALES",
    "#D59AED": "ESOTERISME",
    "#BB99FD": "RELIGION",
    "#92A4FF": "INFORMATIQUE",
    "#7AB9FF": "TECHNIQUES",
    "#83D4F5": "SCIENCES_PURES",
    "#85DBDE": "MEDECINES",
    "#92E4D1": "PRATIQUES",
    "#7ADAA9": "ARTS",
    "#89DA8E": "DOCUMENTATION",
    "#B3E87E": "HISTOIRE",
    "#C7EA77": "GEOGRAPHIE",
    "#DBE591": "CARTES_ATLAS",
    "#CBD7BD": "ENCYCLOPEDIE",
  },
  CLIL_ICON: {
    "#D1C8B3": Litterature_Generale,
    "#F2D60F": Jeunesse,
    "#D8CB95": Jeunes_Adultes,
    "#FFC947": Scolaire,
    "#FFAE4A": Parascolaire,
    "#FC955C": Droit,
    "#F97E60": BD,
    "#F67E75": Management,
    "#FC7E87": Sciences_eco,
    "#FC86B7": Sciences_po,
    "#F395E0": Sciences_hum,
    "#D59AED": Esoterisme,
    "#BB99FD": Religion,
    "#92A4FF": Informatique,
    "#7AB9FF": Techniques,
    "#83D4F5": Sciences,
    "#85DBDE": Medecine,
    "#92E4D1": Livres_pratiques,
    "#7ADAA9": arts,
    "#89DA8E": Documentation,
    "#B3E87E": Histoire,
    "#C7EA77": Geographie,
    "#DBE591": Cartes,
    "#CBD7BD": Encyclopédies,
    "#979797": Sans,
    "": Sans,
  },
  BOOKSCREEN_API: {
    local: "http://localhost:3000/publicShare/book/",
    preprod:
      "https://iz1cgtogwc.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/",
    prod: "https://7bkuyeg6d6.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/",
  },
  SAME_AUTHOR_API: {
    local: "http://localhost:3000/publicShare/book/sameAuthor/",
    preprod:
      "https://iz1cgtogwc.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/sameAuthor/",
    prod: "https://7bkuyeg6d6.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/sameAuthor/",
  },
  SHELFSCREEN_API: {
    local: "http://localhost:3000/publicShare/shelf/",
    preprod:
      "https://iz1cgtogwc.execute-api.eu-west-1.amazonaws.com/v1/publicShare/shelf/",
    prod: "https://7bkuyeg6d6.execute-api.eu-west-1.amazonaws.com/v1/publicShare/shelf/",
  },
  COMMENT_API: {
    local: "http://localhost:3000/publicShare/book/comment/",
    preprod:
      "https://iz1cgtogwc.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/comment/",
    prod: "https://7bkuyeg6d6.execute-api.eu-west-1.amazonaws.com/v1/publicShare/book/comment/",
  },
  SUBSCRIPTION_API: {
    local: "http://localhost:3000/publicShare/subscription",
    preprod:
      "https://iz1cgtogwc.execute-api.eu-west-1.amazonaws.com/v1/publicShare/subscription",
    prod: "https://7bkuyeg6d6.execute-api.eu-west-1.amazonaws.com/v1/publicShare/subscription",
  },
  // SCREEN: wichSize(),
};
