import DefaultPeople from "./DefaultPeople";

const WriteComment = (props) => {
  const { toggleModal } = props;
  return (
    <div className="write-comment-container">
      <div
        className="write-comment"
        style={{ cursor: "pointer" }}
        onClick={() => toggleModal()}
      >
        <div className="write-comment-div">
          <DefaultPeople style="small-people-picture" />
          <p className="write-comment-text">Votre commentaire...</p>
        </div>
        <svg
          className="sendComment"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="16"
            r="15.4792"
            stroke="#B2B2B2"
            strokeWidth="1.04167"
          />
          <path
            d="M17.0327 23.7575L14.7305 17.2694L8.24241 14.9672L22.5939 9.40601L17.0327 23.7575Z"
            stroke="#B2B2B2"
            strokeWidth="1.04167"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.8101 14.1899L14.7305 17.2695"
            stroke="#B2B2B2"
            strokeWidth="1.04167"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default WriteComment;
