import WorkHorizontalList from "./WorkHorizontalList";

//Component affichant les livres similaires
const SimilarsBooks = (props) => {
  let { works } = props;
  return (
    <div className="bookscreen-similarbook-container">
      <div className="bookscreen-similarbook-title-container">
        <h2 className="resumeTitlePolice">Livres similaires</h2>
      </div>
      <WorkHorizontalList works={works} size={"default"} />
    </div>
  );
};

export default SimilarsBooks;
