import React from "react";
import {Helmet} from "react-helmet-async";
import '../index-home.css';
import ficheUser from '../images/FICHE_USER.png';
import scan from '../images/SCAN.png';
import bibliotheque from '../images/BIBLIOTHEQUE.png';
import actus from '../images/ACTUS.png';
import discussion from '../images/DISCUSSION.png';
import achat from '../images/ACHAT.png';

export default class HomeScreen extends React.Component {
    render = () => {
        return (
            <>
                <Helmet>
                    <title>Gleeph - L'écrit nous lie</title>
                    <link href="https://fonts.googleapis.com/css?family=Fira+Sans:300,400" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css?family=Vollkorn" rel="stylesheet" />

                    <meta name="description" content="Le réseau social par les livres" />

                    <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js" defer={true}/>
                    <script src="https://unpkg.com/scrollreveal/dist/scrollreveal.min.js" defer={true}/>
                </Helmet>
                <div id="grad"></div>
                <nav>
                    <div id="navcontainer">
                        <div id="navlogo"><a href="index.html"></a></div>
                        <div id="appstore" className="dl"><a href="https://itunes.apple.com/us/app/gleeph/id1193955842?l=fr&ls=1&mt=8" title="Télécharger pour iPhone"></a></div>
                        <div id="separator"></div>
                        <div id="androidstore" className="dl"><a href="https://play.google.com/store/apps/details?id=net.gleeph.hybridclient" title="Télécharger pour Android"></a></div>
                    </div>
                </nav>
                <section id="landing">
                    <div id="black">
                        <div className="containerhome">
                            <div id="logo"></div>
                        </div>
                    </div>
                    <div className="containerhome">
                        <div id="featurephone">
                            <div id="bg"></div>
                            <div className="slider">
                                <img src={ficheUser} alt=""/>
                                <img src={scan} alt=""/>
                                <img src={bibliotheque} alt=""/>
                                <img src={actus} alt=""/>
                                <img src={discussion} alt=""/>
                                <img src={achat} alt=""/>
                            </div>
                        </div>
                        <div id="cartouche">
                            <p>
                                <span>Téléchargez Gleeph,<br/> l’application de tous les lecteurs</span><br/><br/>
                                Avec Gleeph, gérez votre bibliothèque, partagez vos coups de coeur et recevez chaque jour des suggestions de lecture personnalisées.
                            </p>

                            <div className="badge appstorebadge"><a href="https://itunes.apple.com/us/app/gleeph/id1193955842?l=fr&ls=1&mt=8" title="Télécharger pour iPhone"></a></div>
                            <div className="badge playstorebadge"><a href="https://play.google.com/store/apps/details?id=net.gleeph.hybridclient" title="Télécharger pour Android"></a></div>
                        </div>
                        <div id="arrow"></div>
                    </div>
                </section>
                <section id="scan">
                    <div className="containerhome left">
                        <div className="feature"></div>
                        <div className="featblock reveal">
                            <div className="icon"></div>
                            <p>
                                <span>Bibliothèque virtuelle</span><br/><br/>
                                Grâce au <strong>scanner</strong>, enregistrez rapidement et simplement toute votre bibliothèque. Gardez en mémoire les livres que vous avez lus.
                            </p>
                        </div>
                    </div>
                </section>

                <section id="profile">
                    <div className="containerhome right">
                        <div className="feature reveal"></div>
                        <div className="featblock reveal">
                            <div className="icon"></div>
                            <p>
                                <span>Etagère à découvertes</span><br/><br/>
                                Gleeph ouvre vos horizons. À travers les dernières lectures de vos amis et selon votre profil, <strong>Gleeph vous recommande de nouveaux livres.</strong>
                            </p>
                        </div>
                    </div>
                </section>

                <section id="actus">
                    <div className="containerhome left">
                        <div className="feature reveal"></div>
                        <div className="featblock reveal">
                            <div className="icon"></div>
                            <p>
                                <span>Instigateur de rencontres</span><br/><br/>
                                Grâce au <strong>flux d’actualité</strong> et à la <strong>messagerie instantanée</strong>, gardez un lien avec vos amis, rencontrez de nouvelles personnes et partagez vos expériences de lecture.
                            </p>
                        </div>
                    </div>
                </section>

                <section id="achat">
                    <div className="containerhome right">
                        <div className="feature reveal"></div>
                        <div className="featblock reveal">
                            <div className="icon"></div>
                            <p>
                                <span>Fournisseur de proximité</span><br/><br/>
                                Envie de vous évader tout de suite ? <strong>Gleeph vous indique si votre livre est disponible chez un libraire autour de vous.</strong>
                            </p>
                        </div>
                    </div>
                </section>

                <div id="mobilefeatures"></div>

                <section id="end">
                    <div className="containerhome">
                        <p>
                            Une application 100% gratuite pour redécouvrir<br/>vos proches et vos livres.
                        </p>
                    </div>
                </section>


                <footer>
                    <div className="containerhome">
                        <div id="glp"></div>
                        <ul>
                            <li>Gleeph</li>
                            <li><a href="https://reco.gleeph.net">Moteur de suggestions</a></li>
                            <li><a href="manifeste.html">Manifeste</a></li>
                            <li><a href="soutiens.html">Soutiens</a></li>
                        </ul>

                        <ul>
                            <li>Téléchargement</li>
                            <li><a href="https://itunes.apple.com/us/app/gleeph/id1193955842?l=fr&ls=1&mt=8">iOS</a></li>
                            <li><a href="https://play.google.com/store/apps/details?id=net.gleeph.hybridclient">Android</a></li>
                        </ul>

                        <ul>
                            <li>Support</li>
                            <li><a href="legal.html">Mentions Légales</a></li>
                            <li><a href="https://gleeph.net/CGU_PCG/cguFR.html">C.G.U.</a></li>
                            <li><a href="https://gleeph.net/CGU_PCG/PCGFR.html">Politique de Confidentialité</a></li>
                        </ul>

                        <ul>
                            <li>Contact</li>
                            <li><a href="mailto:contact@gleeph.net">Email</a></li>
                            <li><a href="https://www.facebook.com/gleephapp/">Facebook</a></li>
                            <li><a href="https://www.twitter.com/gleephapp/">Twitter</a></li>
                            <li><a href="https://www.instagram.com/gleephapp/">Instagram</a></li>
                        </ul>

                    </div>
                </footer>
            </>
        )
    }
}
