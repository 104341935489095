import data from "../data/data_test";
import cst from "./constant";

const env = process.env.REACT_APP_BUILD;

var helpers = {
  fetchBook: async function (id) {
    return fetch(cst.BOOKSCREEN_API[env] + id)
      .then((res) => res.json())
      .then(
        (result) => {
          let dataMerge = data.book;
          dataMerge.additionalData = result.additionalData;
          dataMerge.data = result.data;
          dataMerge.similarBooks = result.similarBooks;
          dataMerge.comments = result.comments;
          dataMerge.shelvesWithThisBook = result.shelvesWithThisBook;
          dataMerge.bookOwners = result.bookOwners;
          dataMerge.inSeries = result.inSeries;
          dataMerge.bySameAuthor = result.bySameAuthor;
          return dataMerge;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchShelf: async function (id) {
    return fetch(cst.SHELFSCREEN_API[env] + id)
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchComment: async function (id) {
    return fetch(cst.COMMENT_API[env] + id)
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
};

export default helpers;
