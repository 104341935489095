//Element pour gérer l'affichage des informations du livres
//On enlève edition/page vide ainsi que les dates en TS
const InfoBook = (props) => {
  const { edition, publicationYear, page, className } = props;
  const textPage = page > 0 ? page + " pages" : "";
  const textPublicationYear =
    publicationYear.length !== 4 ? "" : publicationYear;
  let info = [edition, textPublicationYear, textPage];
  info = info.filter((el) => el !== "").filter((el) => el !== undefined);
  info = info.map((el, id) => (
    <span className={className} key={id}>
      {el}
      {info.length === parseInt(id) + 1 ? "" : ", "}
    </span>
  ));

  return <span>{info}</span>;
};

export default InfoBook;
